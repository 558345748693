import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { formatDate } from "@angular/common";
import { UnitsModel } from "src/app/_models/units.model";
import { Router, ActivatedRoute } from "@angular/router";
import {
  OpenStreetMapProvider,
  GeoSearchControl,
  GoogleProvider,
  EsriProvider,
} from "leaflet-geosearch";
import * as L from "leaflet";
import * as H from "esri-leaflet-geocoder";
import { RadioButtonModule } from "primeng/radiobutton";
import { AddressModel } from "src/app/_models/address.model";
import { iUnit } from "src/app/_models/unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material";
import { MapsAPILoader } from "@agm/core";

const ELEMENT_DATA: IClinicReportModel[] = [];
//const provider = new OpenStreetMapProvider();
//const provider = new EsriProvider();
const providerText = GeoSearchControl({
  //provider: new OpenStreetMapProvider(),
  provider: new OpenStreetMapProvider(),
  style: "bar",
  autoComplete: false,
  autoClose: false,
});
var componentNew;

const provider = new GoogleProvider({
  params: {
    key: "AIzaSyAvkcY3R3xdrfQABDFwjV2zd8qnfetRvXs",
  },
});

const searchControl = new GeoSearchControl({
  provider: provider,
});

@Component({
  selector: "medlog-editunit",
  templateUrl: "./editunit.component.html",
  styleUrls: ["./editunit.component.scss"],
})
export class EditUnitComponent implements OnInit {
  private sub: any;
  @ViewChild("search", { static: true }) searchElementRef: ElementRef;

  constructor(
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private healthUnitService: HealthUnitService
  ) {}

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  showTable: boolean = false;
  cols: any[];
  map;
  procura;
  teste;
  type;
  state;
  addressObj = [];
  points = L.layerGroup();
  address: AddressModel;
  loading = false;
  options;

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;
  unit: iUnit;
  bairro;
  cep;
  email;
  clinics: UnitsModel[];
  pt: any;
  healthType;
  stateCombo;
  healthCombo;
  id;
  idType;
  title;

  latitude;
  longitude;
  mapType = "satellite";

  markers;
  center;
  zoom = 11;
  radius;
  google: any;
  public searchControl: FormControl;
  radiusOptions = [
    { label: "Área", value: "" },
    { label: "50m", value: "50" },
    { label: "100m", value: "100" },
    { label: "150m", value: "150" },
    { label: "200m", value: "200" },
    { label: "250m", value: "250" },
    { label: "300m", value: "300" },
    { label: "400m", value: "400" },
    { label: "500m", value: "500" },
  ];

  ngOnInit() {
    this.searchControl = new FormControl();
    this.title = localStorage.getItem("titulo");
    this.loading = true;
    this.address = new AddressModel();
    this.unit = new iUnit();
    this.unit.whatsapp_notification = false;
    this.unit.app_notification = false;
    this.unit.id_confirmation_required = false;
    //this.unit.radius = 50;

    this.center = [];
    this.center.push({ lat: this.latitude, lng: this.longitude });

    this.stateCombo = [];
    this.stateCombo.push({ label: "Selecione Estado", value: null });
    this.stateCombo.push({ label: "AC", value: "AC" });
    this.stateCombo.push({ label: "AL", value: "AL" });
    this.stateCombo.push({ label: "AP", value: "AP" });
    this.stateCombo.push({ label: "AM", value: "AM" });
    this.stateCombo.push({ label: "BA", value: "BA" });
    this.stateCombo.push({ label: "CE", value: "CE" });
    this.stateCombo.push({ label: "DF", value: "DF" });
    this.stateCombo.push({ label: "ES", value: "ES" });
    this.stateCombo.push({ label: "GO", value: "GO" });
    this.stateCombo.push({ label: "MA", value: "MA" });
    this.stateCombo.push({ label: "MT", value: "MT" });
    this.stateCombo.push({ label: "MS", value: "MS" });
    this.stateCombo.push({ label: "MG", value: "MG" });
    this.stateCombo.push({ label: "PA", value: "PA" });
    this.stateCombo.push({ label: "PB", value: "PB" });
    this.stateCombo.push({ label: "PR", value: "PR" });
    this.stateCombo.push({ label: "PE", value: "PE" });
    this.stateCombo.push({ label: "PI", value: "PI" });
    this.stateCombo.push({ label: "RJ", value: "RJ" });
    this.stateCombo.push({ label: "RN", value: "RN" });
    this.stateCombo.push({ label: "RS", value: "RS" });
    this.stateCombo.push({ label: "RO", value: "RO" });
    this.stateCombo.push({ label: "RR", value: "RR" });
    this.stateCombo.push({ label: "SC", value: "SC" });
    this.stateCombo.push({ label: "SP", value: "SP" });
    this.stateCombo.push({ label: "SE", value: "SE" });
    this.stateCombo.push({ label: "TO", value: "TO" });

    this.healthUnitService.getType().subscribe(
      (data) => {
        this.loading = false;
        this.healthType = data;

        this.healthCombo = [];

        for (const type of this.healthType.results) {
          this.healthCombo.push({
            label: type.name,
            value: type.id,
          });
        }
        // for (var i = 0; i < this.healthType.count; i++) {
        //   let field = {
        //     label: this.healthType.results[i].name,
        //     value: this.healthType.results[i].id,
        //   };
        //   this.healthCombo.push(field);
        // }
        this.idType = this.healthType.results[0].id;
        this.sub = this.route.params.subscribe((params) => {
          this.getUnit(params.id);
        });

        componentNew = this;
      },
      (error) => {
        this.loading = false;
        console.error(error);
        return error;
      }
    );

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          for (let local of place.address_components) {
            for (let lugar of local.types) {
              if (lugar == "street_number") {
                this.unit.address_number = local.long_name;
              }

              if (lugar == "route") {
                this.unit.address = local.long_name;
              }

              if (lugar == "sublocality") {
                this.bairro = local.long_name;
              }

              if (lugar == "administrative_area_level_2") {
                this.unit.city = local.long_name;
              }

              if (lugar == "postal_code") {
                this.cep = local.long_name;
              }

              if (lugar == "administrative_area_level_1") {
                let selectedRole = this.stateCombo.find(
                  (x) => x.value == local.short_name
                );
                this.state = selectedRole;
              }
            }
          }
          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.unit.latitude = this.latitude.toString();
          this.unit.longitude = this.longitude.toString();
          this.zoom = 17;
        });
      });
    });
  }

  textForm(lat, long) {
    this.loading = true;

    searchControl.options.provider
      .search({ query: "&latlng=" + lat + "," + long })
      .then((results) => {
        var res = results[0]["label"].split(",");
        this.unit.address = res[0];
        //var stBairro = res[1].split(" - ");
        //this.bairro = stBairro[1];
        //var stCidade = res[2].split(" - ");

        /*if(stCidade[0].trim() != "Brasil"){
        this.unit.city = stCidade[0].trim();
      }*/

        this.unit.latitude = parseFloat(lat).toFixed(6);
        this.unit.longitude = parseFloat(long).toFixed(6);

        this.loading = false;
      });
  }

  onMapClick(e) {
    componentNew.textForm(e.latlng.lat, e.latlng.lng);
    componentNew.points.eachLayer(function (layer) {
      layer.remove();
    });

    componentNew.points.addLayer(
      L.circle(
        [parseFloat(e.latlng.lat), parseFloat(e.latlng.lng)],
        parseInt(componentNew.unit.radius)
      ).addTo(componentNew.map)
    );

    componentNew.points.addLayer(
      L.marker([e.latlng.lat, e.latlng.lng]).addTo(componentNew.map)
    );
    componentNew.map.setZoom(20);
    componentNew.map.panTo([e.latlng.lat, e.latlng.lng]);
  }
  backProfessional() {
    this.router.navigate(["home/units"]);
  }

  markerPin() {
    provider.search({ query: this.procura }).then((results) => {
      this.latitude = results[0].y;
      this.longitude = results[0].x;
      this.zoom = 18;
      this.textFormCEP(results[0].y, results[0].x);
    });
  }

  findLat() {
    var componentScreen = this;
    componentScreen.loading = true;

    H.reverseGeocode()
      .latlng([this.unit.latitude, this.unit.longitude])
      .run(function (error, result) {
        componentScreen.loading = false;
        var str = result.address.Match_addr;
        var res = str.split(",");

        componentScreen.unit.address = res[0];
        componentScreen.bairro = res[1];
        componentScreen.unit.city = res[2];
        componentScreen.latitude = Number(componentScreen.unit.latitude);
        componentScreen.longitude = Number(componentScreen.unit.longitude);
      });
  }

  textFormCEP(lat, long) {
    var componentScreen = this;
    componentScreen.loading = true;
    H.reverseGeocode()
      .latlng([lat, long])
      .run(function (error, result) {
        componentScreen.loading = false;
        var str = result.address.Match_addr;
        var res = str.split(",");

        componentScreen.unit.address = res[0];
        componentScreen.bairro = res[1];
        componentScreen.unit.city = res[2];
        componentScreen.unit.latitude = lat;
        componentScreen.unit.longitude = long;
      });
  }

  searchMap(text) {
    this.procura = text;
  }
  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home/units"]);
    });
  }

  openDialogError(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  keyText() {
    searchControl.options.provider
      .search({ query: this.unit.address })
      .then((results) => {
        this.options = results;
      });
  }

  getUnit(id) {
    this.loading = true;
    this.healthUnitService.getUnit(id).subscribe(
      (data) => {
        this.unit = new iUnit();
        this.unit = data;

        this.id = data["id"];
        this.unit.latitude = data.latitude;
        this.unit.longitude = data.longitude;
        this.state = data.state;
        this.latitude = parseFloat(data.latitude);
        this.longitude = parseFloat(data.longitude);
        this.radius = this.radiusOptions.find(
          (x) => x.value == data.radius.toString()
        );
        this.zoom = 18;
        this.unit.radius = data.radius;
        this.unit.neighbourhood = data.neighbourhood;
        this.bairro = data.neighbourhood;
        this.unit.zip_code = data.zip_code;
        this.cep = data.zip_code;

        let selectedRole = this.stateCombo.find((x) => x.value == data.state);
        this.state = selectedRole;

        let selectedType = this.healthCombo?.find((x) => x.label == data.type);
        this.type = selectedType;

        if (this.type == "undefined") {
          let selectedType = this.healthCombo?.find(
            (x) => x.value == data.type
          );
          this.type = selectedType;
        }
        if (this.type == null) {
          let selectedType = this.healthCombo?.find(
            (x) => x.value == data.type
          );
          this.type = selectedType;
        }

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openDialogError("Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }
  selectAddress(valor) {
    this.markers = [];
    this.center = [];
    this.center.push({ lat: valor.y, lng: valor.x });
    this.zoom = 18;

    var res = valor.label.split(",");
    this.unit.address = res[0];

    this.unit.address = res[0];
    this.latitude = valor.y;
    this.longitude = valor.x;
  }

  selectRadio(element) {
    if (this.unit.latitude) {
      this.radius = this.radiusOptions.find(
        (x) => x.value == element.value.value.toString()
      );
    }
  }
  saveUnit() {
    this.loading = true;
    var unitForm = new iUnit();

    unitForm.name = this.unit.name;
    unitForm.type = this.type.value.toString();
    unitForm.cnpj = this.unit.cnpj;
    unitForm.responsible = this.unit.responsible;
    unitForm.phone_number = this.unit.phone_number;
    unitForm.cel_number = this.unit.cel_number;
    unitForm.address = this.unit.address;
    unitForm.address_number = this.unit.address_number;
    unitForm.address_complement = this.unit.address_complement;
    unitForm.city = this.unit.city;
    unitForm.state = this.state.value.toString();
    unitForm.latitude = parseFloat(this.unit.latitude.toString()).toFixed(6);
    unitForm.longitude = parseFloat(this.unit.longitude.toString()).toFixed(6);
    unitForm.radius = this.radius?.value;
    unitForm.enabled = true;
    unitForm.zip_code = this.cep;
    unitForm.neighbourhood = this.bairro;
    unitForm.whatsapp_notification = this.unit.whatsapp_notification;
    unitForm.app_notification = this.unit.app_notification;
    unitForm.id_confirmation_required = this.unit.id_confirmation_required;

    if (this.unit.latitude != null) {
      this.unit.latitude = this.unit.latitude.toString();
      this.unit.longitude = this.unit.longitude.toString();
    }

    this.healthUnitService.editUnit(unitForm, this.id).subscribe(
      (data) => {
        this.unit = new iUnit();
        this.openDialog("Edição concluído com sucesso!!");
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.openDialogError("Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }

  toggleNotification(text: string, value: Boolean) {
    if (value) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: "570px",
        height: "170px",
        panelClass: "verdePanel",
        data: { description: text + " ativada com sucesso." },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: "570px",
        height: "170px",
        panelClass: "vermelhoPanel",
        data: { description: text + " desativada com sucesso." },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
}
