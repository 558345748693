import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule, NavComponent } from "./shared";
import { HomeComponent } from "./home/home.component";
import {
  MatListModule,
  MatIconModule,
  MatSidenavModule,
  MatButtonModule,
  MatToolbarModule,
  MatTableModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatPaginatorModule,
} from "@angular/material";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MonitoringComponent } from "./home/monitoring/monitoring.component";
import { ClinicReportComponent } from "./home/clinic-report/clinic-report.component";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CalendarModule } from "primeng/calendar";
import { TableModule } from "primeng/table";
import { HoursReportComponent } from "./home/hours-report/hours-report.component";
import { AuthenticationService } from "./_services/authentication.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./_helpers/auth.interceptor";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { DropdownModule } from "primeng/components/dropdown/dropdown";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletMarkerClusterModule } from "@asymmetrik/ngx-leaflet-markercluster";
import { DashBoardComponent } from "./home/dashboard/dashboard.component";
import { PanelModule } from "primeng/panel";
import { UnitsComponent } from "./home/units/units.component";
import { AddUnitsComponent } from "./home/addunits/addunits.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { ProfessionalComponent } from "./home/professional/professional.component";
import { AddProfessionalComponent } from "./home/addprofessional/addprofessional.component";
import { ProfileComponent } from "./home/profile/profile.component";
import { AmChartsModule } from "@amcharts/amcharts3-angular";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogComponent } from "./home/dialog/dialog.component";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { EditUnitComponent } from "./home/editunit/editunit.component";
import { SplitPipe } from "./split.pipe";
import {
  HashLocationStrategy,
  LocationStrategy,
  DatePipe,
  registerLocaleData,
} from "@angular/common";
import { CheckinComponent } from "./home/checkin/checkin.component";
import { ClinicReportUserComponent } from "./home/clinic-report-user/clinic-report-user.component";
import { CadProfessionalComponent } from "./home/cadprofessional/cadprofessional.component";
import { AgmCoreModule } from "@agm/core";
import { AccordionModule } from "primeng/accordion";
import { AngularDraggableModule } from "angular2-draggable";
import { AgmOverlays } from "agm-overlays";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { DetailRegistroComponent } from "./home/detailregistro/detailregistro.component";
import { EditRegistroComponent } from "./home/editregistro/editregistro.component";
import { GridComponent } from "./home/grid/grid.component";
import { ScheduleComponent } from "./home/schedule/schedule.component";
import { CreateScheduleComponent } from "./home/create-schedule/create-schedule.component";
import { NgxCurrencyModule } from "ngx-currency";
import { ScheduleTableComponent } from "./home/schedule-table/schedule-table.component";
import { ScheduleInsertComponent } from "./home/schedule-insert/schedule-insert.component";
import { ConfirmDialogComponent } from "./home/confirm-dialog/confirm-dialog.component";
import { DialogEditComponent } from "./home/dialog-edit/dialog-edit.component";
import { ScheduleHistoryComponent } from "./home/schedule-history/schedule-history.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ScheduleReportComponent } from "./home/schedule-report/schedule-report.component";
import { DateFilterPipe } from "./date-filter.pipe";
import {
  DateTimeAdapter,
  OWL_DATE_TIME_FORMATS,
  OWL_DATE_TIME_LOCALE,
} from "ng-pick-datetime";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { HomeModule } from "./home/home.module";
import { CountUpModule } from "countup.js-angular2";
import { MultiSelectModule } from "primeng/multiselect";
import { ResetReportComponent } from "./home/reset-report/reset-report.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CreateGridComponent } from "./home/create-grid/create-grid.component";
import { CREATE_GRID_TOKEN } from "./home/create-grid/create-grid.token";
import { OverlayModule } from "@angular/cdk/overlay";
import { ScheduleMonitoringModalComponent } from "./home/schedule-monitoring-modal/schedule-monitoring-modal.component";
import { SCHEDULE_MONITORING_MODAL_TOKEN } from "./home/schedule-monitoring-modal/schedule-monitoring-modal.token";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { NotificationScheduleComponent } from "./home/notification-schedule/notification-schedule.component";
import { NotificationUnitComponent } from "./home/notification-unit/notification-unit.component";
import { PointRecordComponent } from "./home/point-record/point-record.component";
import localePt from "@angular/common/locales/pt";
import { ScheduleMonitoringComponentV2 } from "./home/schedule-monitoring-v2/schedule-monitoring-v2.component";
import { CompanyComponent } from "./home/company/company.component";
// Registre o locale para pt-BR
registerLocaleData(localePt, "pt-BR");

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavComponent,
    MonitoringComponent,
    ClinicReportComponent,
    LoginComponent,
    HoursReportComponent,
    DashBoardComponent,
    UnitsComponent,
    AddUnitsComponent,
    ProfessionalComponent,
    AddProfessionalComponent,
    ProfileComponent,
    DialogComponent,
    EditUnitComponent,
    SplitPipe,
    CheckinComponent,
    ClinicReportUserComponent,
    CadProfessionalComponent,
    DetailRegistroComponent,
    EditRegistroComponent,
    GridComponent,
    CreateGridComponent,
    ScheduleComponent,
    CreateScheduleComponent,
    ScheduleTableComponent,
    ScheduleInsertComponent,
    ConfirmDialogComponent,
    DialogEditComponent,
    ScheduleMonitoringComponentV2,
    ScheduleReportComponent,
    DateFilterPipe,
    ResetReportComponent,
    ScheduleMonitoringModalComponent,
    NotificationUnitComponent,
    NotificationScheduleComponent,
    PointRecordComponent,
    CompanyComponent,
  ],
  entryComponents: [
    DialogComponent,
    DetailRegistroComponent,
    EditRegistroComponent,
    CreateScheduleComponent,
    ScheduleInsertComponent,
    ConfirmDialogComponent,
    DialogEditComponent,
    HoursReportComponent,
    ResetReportComponent,
    GridComponent,
    CreateGridComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatAutocompleteModule,
    AppRoutingModule,
    OverlayModule,
    MatPaginatorModule,
    AutoCompleteModule,
    CalendarModule,
    TableModule,
    HttpClientModule,
    DropdownModule,
    LeafletModule.forRoot(),
    LeafletMarkerClusterModule,
    PanelModule,
    RadioButtonModule,
    AmChartsModule,
    MatDialogModule,
    AccordionModule,
    CountUpModule,
    AgmOverlays,
    AngularDraggableModule,
    NgxCurrencyModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    HomeModule,
    MatMenuModule,
    MultiSelectModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAvkcY3R3xdrfQABDFwjV2zd8qnfetRvXs",
      libraries: ["places"],
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
    MatGoogleMapsAutocompleteModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatSlideToggleModule,
  ],
  exports: [MatFormFieldModule],
  providers: [
    AuthenticationService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: CREATE_GRID_TOKEN, useValue: CreateGridComponent },
    {
      provide: SCHEDULE_MONITORING_MODAL_TOKEN,
      useValue: ScheduleMonitoringModalComponent,
    },
    { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
