import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HealthTeamService {
  constructor(private http: HttpClient) {}

  public getHealthTeam(schedule) {
    return this.http.get<any>(
      environment.apiUrl + "health-team/?schedule_id=" + schedule
    );
  }

  public saveHealthTeam(id, data) {
    return this.http.patch<any>(
      environment.apiUrl + "health-team/" + id + "/",
      data
    );
  }

  public postHealthTeam(body) {
    return this.http.post<any>(environment.apiUrl + "health-team/", body);
  }
}
