import { Component, OnInit, HostListener } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHourReportModel } from "src/app/_models/hour-report.model";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { EditRegistroComponent } from "../editregistro/editregistro.component";
import { getDate, lastDayOfMonth, set, subHours } from "date-fns";
import {
  DateTimeAdapter,
  OwlDateTimeIntl,
  OwlDateTimeComponent,
} from "@danielmoncada/angular-datetime-picker";
import { ResetReportComponent } from "../reset-report/reset-report.component";
import { OccupationFieldService } from "src/app/_services/occupation-fied.service";
import { ReportsService } from "src/app/_services/reports.service";
import { WorkShiftService } from "src/app/_services/work-shift.service";
import { ConsolidatedShiftService } from "src/app/_services/consolidated-shift.service";
@Component({
  selector: "medlog-hours-report",
  templateUrl: "./hours-report.component.html",
  styleUrls: ["./hours-report.component.scss"],
})
export class HoursReportComponent implements OnInit {
  specialty: any = {
    name: "Todas as Especialidades",
  };
  specialtys: any[] = [
    {
      name: "Todas as Especialidades",
    },
  ];
  ocupationCombo: any[];
  manualentry: any;
  manualeave: any;
  manualhours: number;
  adjusted_end_datetime: string;
  adjusted_start_datetime: string;
  constructor(
    private owlDateTimeIntl: OwlDateTimeIntl,
    private healthUnitService: HealthUnitService,
    private occupationFieldService: OccupationFieldService,
    private reportsService: ReportsService,
    private consolidatedShiftService: ConsolidatedShiftService,
    private workShiftService: WorkShiftService,
    public dialog: MatDialog,
    private healthProfessionalService: HealthProfessionalsService,
    private dateTimeAdapter: DateTimeAdapter<any>
  ) {
    document.getElementById("menu").click();
    this.dateTimeAdapter.setLocale("pt-BR");
    owlDateTimeIntl.setBtnLabel = "";
    owlDateTimeIntl.cancelBtnLabel = "";
  }
  especialidade;
  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  showTable: boolean = false;
  cols: any[];
  totalHours;
  totalAdjustedHours;
  healthUnits;
  selectedUnit;
  filterValue;
  healthProfessionals: any = {
    results: [
      {
        name: "Todos os Profissionais",
        user_type: 0,
        email: 0,
      },
    ],
  };
  selectedProfessional: any = {
    name: "Todos os Profissionais",
  };
  pt: any;
  modelDataInicio: any = set(new Date(), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  modelDataFim: any = set(new Date(), {
    date: getDate(lastDayOfMonth(new Date())),
    hours: 23,
    minutes: 59,
    seconds: 59,
  });
  datainicial;
  dataFinal;
  datainicialFormatada;
  datafinalFormatada;
  reportUnitis;
  clinics;
  totalDoctor;
  public unitFilter: any;
  public csvFilter: IHealthUnit;
  loading = true;
  selectedCategory: any = {
    name: "Todas as Categorias",
  };
  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  report: IHourReportModel[] = [];
  title;
  searchText: string = "";
  private Result = [];
  dialogs: DetailRegistroComponent;
  dateTime;
  ocupation;
  @HostListener("input") oninput() {
    this.searchItems();
  }

  private checkAgainstProperty(property: any): boolean {
    let value: boolean = false;

    if (property.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0) {
      value = true;
    }

    return value;
  }
  chosenDateHandler(datepicker: OwlDateTimeComponent<any>, input: string) {
    if (input === "ATE") {
      this.modelDataFim = datepicker?.selected;
    } else {
      this.modelDataInicio = datepicker?.selected;
    }
  }

  searchItems() {
    this.Result = this.report.filter((o) =>
      this.checkAgainstProperty(o.doctor)
    );

    if (this.Result.length === 0) {
      return [-1];
    }
    return this.Result;
  }

  ngOnInit() {
    this.loading = true;
    this.dateTime = new Date();
    this.title = localStorage.getItem("titulo");

    this.healthUnitService.getHealthUnits().subscribe((data) => {
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.results.unshift({ name: "Todas as unidades" });

      //this.healthUnits.results.reverse();
    });
    this.occupationFieldService.getOccupation().subscribe((data) => {
      this.ocupation = data;

      this.ocupation.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.ocupation.results.unshift({
        name: "Todas as Categorias",
      });
      this.loading = false;
    });

    this.selectedUnit = { name: "Todas as Unidades" };
    this.selectedProfessional = {
      name: "Todos os Profissionais",
      user_type: 0,
      email: 0,
    };
    this.healthProfessionalService
      .getCompanyHealthProfessionalsCategory()
      .subscribe((data) => {
        this.healthProfessionals = data;

        this.healthProfessionals.results.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        this.healthProfessionals.results.unshift({
          name: "Todos os Profissionais",
          user_type: 0,
          email: 0,
        });

        //this.healthProfessionals.results.reverse();
        this.loading = false;
      });
    var initialDate = new Date();

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };
  }

  exportar() {
    this.csvFilter = new IHealthUnit();

    this.datainicial = new Date(
      this.modelDataInicio + new Date().getTimezoneOffset() * 60000
    ).toISOString();

    this.dataFinal = new Date(
      this.modelDataFim + new Date().getTimezoneOffset() * 60000
    ).toISOString();

    this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
    this.dataFinal = this.dataFinal.substring(0, 17) + "59.000Z";

    if (this.selectedUnit.id) {
      this.csvFilter.health_unit_id = this.selectedUnit.id;
    }

    if (this.selectedProfessional.health_professional_id) {
      this.csvFilter.health_professionals_id = [
        this.selectedProfessional.health_professional_id,
      ];
    } else {
      this.csvFilter.health_professionals_id = [];
    }

    this.loading = true;

    this.csvFilter.start_datetime = this.datainicial;
    this.csvFilter.end_datetime = this.dataFinal;
    this.csvFilter.occupation_area = this.selectedCategory.id;
    if (this.specialty.id) this.csvFilter.area_of_expertise = this.specialty.id;

    this.reportsService.reportCSV(this.csvFilter).subscribe(
      (data) => {
        this.loading = false;
        window.open(data["report_file"]);

        return true;
      },
      (error) => {
        this.loading = false;
        return error;
      }
    );
  }
  getProfessionals(search?: string) {
    this.healthProfessionalService
      .getCompanyHealthProfessionalsCategory(
        this.selectedCategory.id || null,
        null,
        search || null
      )
      .subscribe((data) => {
        this.healthProfessionals = data;

        this.healthProfessionals.results.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        this.healthProfessionals.results.unshift({
          name: "Todos os Profissionais",
          user_type: 0,
          email: 0,
        });

        //this.healthProfessionals.results.reverse();
        this.loading = false;
      });
  }

  exportarPDF() {
    this.csvFilter = new IHealthUnit();

    this.datainicial = new Date(
      this.modelDataInicio + new Date().getTimezoneOffset() * 60000
    ).toISOString();
    this.dataFinal = new Date(
      this.modelDataFim + new Date().getTimezoneOffset() * 60000
    ).toISOString();
    if (this.selectedUnit.id) {
      this.csvFilter.health_unit_id = this.selectedUnit.id;
    }

    if (this.selectedProfessional.health_professional_id) {
      this.csvFilter.health_professionals_id = [
        this.selectedProfessional.health_professional_id,
      ];
    } else {
      this.csvFilter.health_professionals_id = [];
    }

    this.loading = true;
    this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
    this.dataFinal = this.dataFinal.substring(0, 17) + "59.000Z";

    this.csvFilter.start_datetime = this.datainicial;
    this.csvFilter.end_datetime = this.dataFinal;
    this.csvFilter.occupation_area = this.selectedCategory.id;
    if (this.specialty.id) this.csvFilter.area_of_expertise = this.specialty.id;

    this.reportsService.reportUnityPDF(this.csvFilter).subscribe(
      (data) => {
        this.loading = false;
        window.open(data["report_file"]);

        return true;
      },
      (error) => {
        this.loading = false;
        return error;
      }
    );
  }

  reportHours() {
    if (!this.selectedUnit.id && !this.selectedProfessional.id) {
      this.openDialog("Selecione um profissional ou uma unidade.");
      return;
    }
    this.cols = [
      { field: "doctor", header: "Profissionais de saúde" },
      { field: "clinic", header: "Unidade" },
      { field: "entry", header: "Entrada" },
      { field: "manualentry", header: "Entrada Ajustada" },
      { field: "leave", header: "Saída" },
      { field: "manualeave", header: "Saída Ajustada" },
      { field: "hours", header: "Tempo" },
      { field: "manualhours", header: "Tempo Ajustado" },
      { field: "adjustedat", header: "Ajustado em" },
      { field: "action", header: "" },
    ];

    this.totalDoctor = 0;
    this.reportUnitis = null;
    this.report = [];

    if (
      this.selectedUnit &&
      this.modelDataInicio != 0 &&
      this.modelDataFim != 0
    ) {
      this.dataFinal = new Date(this.modelDataFim);
      // this.dataFinal.setHours(
      //   this.dataFinal.getHours() - this.dataFinal.getTimezoneOffset() / 60
      // );
      // this.dataFinal = this.dataFinal.toISOString();

      this.datainicial = new Date(this.modelDataInicio);
      // this.datainicial.setHours(
      //   this.datainicial.getHours() - this.datainicial.getTimezoneOffset() / 60
      // );
      // this.datainicial = this.datainicial.toISOString();

      console.log(this.dataFinal, this.datainicial);

      this.unitFilter = {};
      if (this.selectedUnit.id) {
        this.unitFilter.health_unit_id = this.selectedUnit.id;
      }
      this.unitFilter.start_datetime = this.datainicial;
      this.unitFilter.end_datetime = this.dataFinal;

      if (this.selectedProfessional.health_professional_id) {
        this.unitFilter.health_professionals_id = [
          this.selectedProfessional.health_professional_id,
        ];
      } else {
        this.unitFilter.health_professionals_id = [];
      }
      if (this.selectedCategory.id) {
        this.unitFilter.occupation_area = this.selectedCategory.id;
      }

      if (this.specialty.id)
        this.unitFilter.area_of_expertise = this.specialty.id;

      this.unitFilter.all_work_shifts = true;
      this.loading = true;

      this.reportsService.reportHours(this.unitFilter).subscribe(
        (data) => {
          this.showTable = true;
          this.loading = false;
          this.Result = null;
          this.reportUnitis = null;
          this.reportUnitis = data;

          this.totalHours = this.reportUnitis.total_worked_hours;
          this.totalAdjustedHours =
            this.reportUnitis.total_adjusted_worked_hours;
          if (this.reportUnitis.worked_shifts.length == 0) {
            this.openDialog("Não foram encontrados registros");
            return;
          } else {
            this.totalDoctor = this.reportUnitis.health_professionals_quantity;
            for (let i = 0; i < this.reportUnitis.worked_shifts.length; i++) {
              var entradaManual;
              var saidaManual;
              var entradaManualDate;
              var saidaManualDate;
              var totalhoras;
              var totalManual;
              var entrada;
              var saida;
              var ajustado;
              var ttDate;
              var ttDates;

              if (this.reportUnitis.worked_shifts[i].adjusted_start_datetime) {
                entradaManual =
                  new Date(
                    this.reportUnitis.worked_shifts[i].adjusted_start_datetime
                  ).toLocaleDateString() +
                  " - " +
                  new Date(
                    this.reportUnitis.worked_shifts[i].adjusted_start_datetime
                  ).toLocaleTimeString();
                entradaManualDate = new Date(
                  this.reportUnitis.worked_shifts[i].adjusted_start_datetime
                );
              } else {
                entradaManual = "";
                entradaManualDate = new Date(
                  this.reportUnitis.worked_shifts[i].start_datetime
                );
              }

              if (this.reportUnitis.worked_shifts[i].adjusted_end_datetime) {
                saidaManual =
                  new Date(
                    this.reportUnitis.worked_shifts[i].adjusted_end_datetime
                  ).toLocaleDateString() +
                  " - " +
                  new Date(
                    this.reportUnitis.worked_shifts[i].adjusted_end_datetime
                  ).toLocaleTimeString();
                saidaManualDate = new Date(
                  this.reportUnitis.worked_shifts[i].adjusted_end_datetime
                );
              } else {
                saidaManual = "";
                saidaManualDate = new Date(
                  this.reportUnitis.worked_shifts[i].end_datetime
                );
              }

              if (this.reportUnitis.worked_shifts[i].worked_hours) {
                totalhoras = this.formatHour(
                  this.reportUnitis.worked_shifts[i].worked_hours
                );
              } else {
                totalhoras = 0;
              }

              if (this.reportUnitis.worked_shifts[i].adjusted_worked_hours) {
                totalManual =
                  this.reportUnitis.worked_shifts[i].adjusted_worked_hours;
              } else {
                totalManual = 0;
              }

              if (this.reportUnitis.worked_shifts[i].start_datetime) {
                entrada =
                  new Date(
                    this.reportUnitis.worked_shifts[i].start_datetime
                  ).toLocaleDateString() +
                  " - " +
                  new Date(
                    this.reportUnitis.worked_shifts[i].start_datetime
                  ).toLocaleTimeString();
                ttDate = new Date(
                  this.reportUnitis.worked_shifts[i].start_datetime
                );
                ttDate.setDate(ttDate.getDate() + 1);
                var ttDatemin = new Date(
                  this.reportUnitis.worked_shifts[i].start_datetime
                );
                ttDatemin.setDate(ttDatemin.getDate() - 1);
              } else {
                entrada = "__ /__ /____ __ : __";
                ttDate = new Date(
                  this.reportUnitis.worked_shifts[i].adjusted_start_datetime
                );
                ttDate.setDate(ttDate.getDate() + 1);
                var ttDatemin = new Date(
                  this.reportUnitis.worked_shifts[i].adjusted_start_datetime
                );
                ttDatemin.setDate(ttDatemin.getDate() - 1);
              }

              if (this.reportUnitis.worked_shifts[i].end_datetime) {
                saida =
                  new Date(
                    this.reportUnitis.worked_shifts[i].end_datetime
                  ).toLocaleDateString() +
                  " - " +
                  new Date(
                    this.reportUnitis.worked_shifts[i].end_datetime
                  ).toLocaleTimeString();
                ttDates = new Date(
                  this.reportUnitis.worked_shifts[i].end_datetime
                );
                ttDates.setDate(ttDates.getDate() + 1);
                var ttDatesmin = new Date(
                  this.reportUnitis.worked_shifts[i].end_datetime
                );
                ttDatesmin.setDate(ttDatesmin.getDate() - 1);
              } else {
                saida = "__ /__ /____ __ : __";
                ttDates = new Date(
                  this.reportUnitis.worked_shifts[i].adjusted_end_datetime
                );
                ttDates.setDate(ttDates.getDate() + 1);
                var ttDatesmin = new Date(
                  this.reportUnitis.worked_shifts[i].adjusted_end_datetime
                );
                ttDatesmin.setDate(ttDatesmin.getDate() - 1);
              }

              if (this.reportUnitis.worked_shifts[i].adjusted_at) {
                ajustado =
                  new Date(
                    this.reportUnitis.worked_shifts[i].adjusted_at
                  ).toLocaleDateString() +
                  " - " +
                  new Date(
                    this.reportUnitis.worked_shifts[i].adjusted_at
                  ).toLocaleTimeString();
              } else {
                ajustado = "__ /__ /____ __ : __";
              }

              let pro = {
                id: this.reportUnitis.worked_shifts[i].id,
                image:
                  this.reportUnitis.worked_shifts[i].health_professional_photo,
                doctor:
                  this.reportUnitis.worked_shifts[i].health_professional_name,
                clinic: this.reportUnitis.worked_shifts[i].health_unit_name,
                health_professional_id:
                  this.reportUnitis.worked_shifts[i].health_professional_id,
                entry: entrada,
                manualentry: entradaManual,
                manualentryDate: entradaManualDate,
                leave: saida,
                manualeave: saidaManual,
                manualeaveDate: saidaManualDate,
                hours: totalhoras,
                minlimitDate: ttDatemin,
                limitDate: ttDate,
                limitDateSaida: ttDates,
                minlimitDateSaida: ttDatesmin,
                manualhours: totalManual,
                adjustedat: ajustado,
                health_professional_document:
                  this.reportUnitis.worked_shifts[i]
                    .health_professional_document,
                disable_adjustment:
                  this.reportUnitis.worked_shifts[i].disable_adjustment,
                disable_work_shift:
                  this.reportUnitis.worked_shifts[i].disable_work_shift,
                action: false,
              };

              this.report.push(pro);

              this.Result = this.report;
            }
          }

          return true;
        },
        (error) => {
          return error;
        }
      );
    } else {
      this.loading = false;
      this.openDialog("Necessário preencher os campos.");
    }
  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
  search(event) {
    this.results = this.healthUnits.results;
  }

  formatDate(data: Date) {
    //"2019-04-01T22:23:52Z"
    var year = data.getFullYear();
    var month = data.getMonth() + 1;
    var day = data.getDay();
    var hour = data.getHours();
    var minute = data.getMinutes();
    var newMonth;
    var newDay;

    if (month < 10) {
      newMonth = "0" + month;
    }

    if (day < 10) {
      newDay = "0" + day;
    }

    return (
      year + "-" + newMonth + "-" + newDay + "T" + hour + ":" + minute + ":00Z"
    );
    //return year + "-" + newMonth + "-" + newDay;
  }

  formatDatePT(data: Date) {
    return data.toLocaleDateString();
  }

  openEditDIalog(valor) {
    const dialogRef = this.dialog.open(EditRegistroComponent, {
      width: "1090px",
      height: "347px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: { description: valor, selectUnity: this.selectedUnit.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reportHours();
      }
    });
  }

  onKeyDate(event: any) {
    event.target.value = event.target.value.replace(
      /^(\d{2})(\d{2})(\d{4})/,
      "$1/$2/$3"
    );
  }

  openDialogRegistro() {
    const dialogRef = this.dialog.open(DetailRegistroComponent, {
      width: "1000px",
      height: "200px",
      panelClass: "azulPanel",
      disableClose: true,
      data: { description: "" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const dialogRefs = this.dialog.open(DialogComponent, {
          width: "570px",
          height: "170px",
          panelClass: "vermelhoPanel",
          data: {
            description: "Tem certeza que deseja adicionar esse registro?",
            enableCancel: true,
          },
        });

        dialogRefs.afterClosed().subscribe((results) => {
          if (results == "0") {
            /*abrir modal*/
            this.cols = [
              { field: "doctor", header: "Profissionais de saúde" },
              { field: "clinic", header: "Unidade" },
              { field: "entry", header: "Entrada" },
              { field: "manualentry", header: "Entrada Ajustada" },
              { field: "leave", header: "Saída" },
              { field: "manualeave", header: "Saída Ajustada" },
              { field: "hours", header: "Tempo" },
              { field: "manualhours", header: "Tempo Ajustado" },
              { field: "adjustedat", header: "Ajustado em" },
              { field: "action", header: "" },
            ];
            this.showTable = true;

            this.consolidatedShiftService
              .createConsolidedShift(result)
              .subscribe(
                (data) => {
                  if (data.id != "") {
                    var entradaManual =
                      new Date(result.modelDataInicio).toLocaleDateString() +
                      " - " +
                      new Date(result.modelDataInicio).toLocaleTimeString();
                    var saidaManual =
                      new Date(result.modelDataFim).toLocaleDateString() +
                      " - " +
                      new Date(result.modelDataFim).toLocaleTimeString();

                    let pro = {
                      id: data.id,
                      image: result.image,
                      doctor: result.doctor,
                      clinic: result.clinic,
                      health_professional_id: data.health_professional,
                      entry: "__ /__ /____ __ : __",
                      manualentryDate: result.modelDataInicio,
                      manualentry: entradaManual,
                      manualeaveDate: result.modelDataFim,
                      leave: "__ /__ /____ __ : __",
                      manualeave: saidaManual,
                      hours: "0",
                      manualhours: data.adjusted_worked_hours,
                      adjustedat: "__ /__ /____ __ : __",
                      action: false,
                    };

                    this.report.push(pro);
                    this.Result = this.report;
                    this.openDialogSucesso("Registro adicionado com sucesso.");
                  }

                  this.loading = false;
                  return true;
                },
                (error) => {
                  this.openDialog("Não foi possível realizar o registro!");
                  this.loading = false;
                  return error;
                }
              );
          } else {
            //cancel
          }
        });
      }
    });
  }

  openDialogDisable(texto, valor, tipo): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        var parametros = new IHealthUnit();
        parametros.disable_work_shift = tipo;

        this.loading = true;

        this.workShiftService.disableWorkShift(parametros, valor.id).subscribe(
          (data) => {
            if (data.id != "") {
              if (tipo) {
                this.openDialogSucesso("Desativado com sucesso.");
              } else {
                this.openDialogSucesso("Ativado com sucesso.");
              }
              this.reportHours();
            }

            this.loading = false;

            return true;
          },
          (error) => {
            this.loading = false;
            return error;
          }
        );
      } else {
      }
    });
  }

  editManual(valor, tipo) {
    if (tipo) {
      this.openDialogDisable("Deseja desativar o registro?", valor, tipo);
    } else {
      this.openDialogDisable("Deseja ativar o registro?", valor, tipo);
    }

    //editConsolidedShift
  }

  verifyDate(valor) {
    valor.action = true;
  }
  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getSpecialty(id) {
    this.occupationFieldService.getOccupationById(id).subscribe((data) => {
      this.specialtys = data.areas_of_expertise;

      this.specialtys.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      this.specialtys.unshift({
        name: "Todas as Especialidades",
      });
      this.loading = false;
    });
  }

  resetReportData(valor) {
    const dialogRef = this.dialog.open(ResetReportComponent, {
      width: "1090px",
      height: "347px",
      panelClass: "azulPanel",
      data: { description: valor, selectUnity: this.selectedUnit.id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reportHours();
      }
    });
  }

  shouldShowResetButton(item: any): boolean {
    // !valor || valor !== ''
    // return !item.manualentry || item.manualentry !=='' && !item.manualeave || item.manualeave !=='' && !item.manualhours || item.manualhours !=='';
    const isNotNullOrEmpty = (value: any) => value !== null && value !== "";
    return (
      isNotNullOrEmpty(item.manualentry) && isNotNullOrEmpty(item.manualeave)
    );
  }
  print(event) {}
}
