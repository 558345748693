import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PositionCheckService {
  constructor(private http: HttpClient) {}

  public checkIn(body) {
    return this.http.post<any>(environment.apiUrl + "position-check/", body);
  }
}
