import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { DialogComponent } from "../dialog/dialog.component";
import {
  DateTimeAdapter,
  OwlDateTimeIntl,
  OwlDateTimeComponent,
} from "@danielmoncada/angular-datetime-picker";
import { ConsolidatedShiftService } from "src/app/_services/consolidated-shift.service";

export interface DialogData {
  description: any;
  enableCancel: boolean;
  selectUnity: string;
  acompanhamento: boolean;
  prof_id: string;
  occupation_area_id: string;
  noShow: boolean;
}

@Component({
  selector: "medlog-editregistro",
  templateUrl: "./editregistro.component.html",
  styleUrls: ["./editregistro.component.scss"],
})
export class EditRegistroComponent implements OnInit {
  constructor(
    private dateTimeAdapter: DateTimeAdapter<any>,
    private owlDateTimeIntl: OwlDateTimeIntl,
    public dialogRef: MatDialogRef<EditRegistroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private consolidatedShiftService: ConsolidatedShiftService,
    public dialog: MatDialog
  ) {
    this.dateTimeAdapter.setLocale("pt-BR");
    owlDateTimeIntl.setBtnLabel = "";
    owlDateTimeIntl.cancelBtnLabel = "";
  }
  healthUnits;
  selectedUnit;
  periods = {
    results: [{ name: "Selecione o periodo", id: null }],
  };
  selectedPeriod = { name: "Selecione o periodo", id: null };
  healthProfessionals;
  selectedProfessional;
  loading = true;
  pt: any;
  dateTime;
  modelDataInicio;
  modelDataFim;
  datainicial;
  dataFinal;
  datainicialFormatada;
  datafinalFormatada;
  editModel: any;
  dateFormat = "dd/MM/yy - hh:mm:ss";
  gradeData: any = {
    results: [],
  };
  isManual = false;
  selectedGrade: any;
  onNoClick(): void {
    this.dialogRef.close("0");
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
  chosenDateHandler(datepicker: OwlDateTimeComponent<any>, input: string) {
    if (input === "ATE") {
      this.modelDataFim = datepicker.selected;
    } else {
      this.modelDataInicio = datepicker.selected;
    }
  }

  async ngOnInit() {
    this.loading = true;
    this.editModel = { ...this.data.description };

    const params = {
      health_unit_status: "enable",
    };
    this.loading = true;
    await this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      this.healthUnits = (data as any).results;

      this.healthUnits.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.unshift({ name: "Selecione a Unidade", id: 0 });
      if (this.data.description.clinic && this.healthUnits) {
        const clinic = this.healthUnits.filter(
          (unit) => unit.name === this.data.description.clinic
        );
        this.selectedUnit = { ...clinic[0] };
      }
    });
    // if(!this.data.occupation_area_id) this.data.occupation_area_id = null;

    this.loading = true;
    await this.healthProfessionalService
      .getCompanyHealthProfessionalsCategory(
        this.data.occupation_area_id ? this.data.occupation_area_id : null
      )
      .subscribe((data) => {
        this.healthProfessionals = data;

        this.healthProfessionals.results.push({
          name: "--TODOS OS PROFISSIONAIS--",
          user_type: 0,
          email: 0,
        });

        this.healthProfessionals.results.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });

        //this.healthProfessionals.results.reverse();
        if (this.data.prof_id) {
          const selectProf = this.healthProfessionals.results.find(
            (prof) => prof.health_professional_id === this.data.prof_id
          );
          if (selectProf) {
            this.editModel.doctor = selectProf;
            this.selectedProfessional = selectProf;
            this.editModel.health_professional_id =
              selectProf.health_professional_id;
          }
        } else {
          const selectProf = this.healthProfessionals.results.find(
            (prof) =>
              prof.health_professional_id ===
              this.editModel.health_professional_id
          );
          if (selectProf) {
            this.selectedProfessional = selectProf;
            this.editModel.health_professional_id =
              selectProf.health_professional_id;
          }
        }
        this.loading = false;
      });

    ////  this.editModel.entry = this.editModel.entry ?  this.editModel.entry.toString().replace(' -', ',') : null
    // this.editModel.leave = this.editModel.leave ? this.editModel.leave.toString().replace(' -', ',') : null;

    // if ((!this.editModel.entry || this.editModel.entry === '__ /__ /____ __ : __') && this.editModel.manualentryDate) {
    //   this.editModel.entry = format(this.editModel.manualentryDate, 'dd/mm/yyyy HH:mm:ss', { locale: ptBR });
    // }

    // if ((!this.editModel.leave || this.editModel.leave === '__ /__ /____ __ : __') && this.editModel.manualeaveDate) {
    //   this.editModel.leave = format(this.editModel.manualeaveDate, 'dd/MM/yyyy HH:mm:ss', { locale: ptBR });
    // }

    let entry: string[] = [];

    // if(this.editModel.manualentryDate != ''){
    //   entry =   format( this.editModel.manualentryDate, 'dd/mm/yyyy HH:mm:ss', { locale: ptBR }) ;

    // }else{

    if (this.editModel?.manualentry) {
      entry = this.editModel?.manualentry?.split(" ");
      this.isManual = true;
    } else if (this.editModel?.entry) {
      entry = this.editModel?.entry?.split(" ");
    } else if (this.editModel?.manualentryDate) {
      entry = this.editModel?.manualentryDate?.split(" ");
    } else {
      entry = ["00", "00", "00"];
    }

    if (entry.length === 3) {
      entry[1] = entry[2];
    }
    // }

    const [ddEntry, mmEntry, anoEntry] = entry[0].split("/");

    this.modelDataInicio = new Date(
      anoEntry + "-" + mmEntry + "-" + ddEntry + "T" + entry[1]
    );

    let leave: string[] = [];
    // if(this.editModel.manualeaveDate != ''){
    //   leave = format(this.editModel.manualeaveDate, 'MM/dd/yyyy HH:mm:ss', { locale: ptBR });
    // }else{

    if (this.editModel.manualeave && this.editModel.manualeave != "") {
      leave = this.editModel.manualeave.split(" ");
    } else if (this.editModel.entry) {
      leave = this.editModel.leave.split(" ");
    } else if (this.editModel.manualeaveDate) {
      leave = this.editModel.manualeaveDate.split(" ");
    } else {
      leave = ["00", "00", "00"];
    }

    if (leave.length === 3) {
      leave[1] = leave[2];
    }
    // }

    const [ddLeave, mmLeave, anoLeave] = leave[0].split("/");
    this.modelDataFim = new Date(
      anoLeave + "-" + mmLeave + "-" + ddLeave + "T" + leave[1]
    );

    this.dateTime = new Date();

    let unidade;

    // else {
    //   this.healthProfessionalService
    //     .getHealthProfessionals()
    //     .subscribe((data) => {
    //       this.healthProfessionals = data;

    //       this.healthProfessionals.results.push({
    //         name: "--TODOS OS PROFISSIONAIS--",
    //         user_type: 0,
    //         email: 0,
    //       });

    //       this.healthProfessionals.results.sort(function (a, b) {
    //         var nameA = a.name.toLowerCase(),
    //           nameB = b.name.toLowerCase();
    //         if (nameA < nameB)
    //           //sort string ascending
    //           return -1;
    //         if (nameA > nameB) return 1;
    //         return 0; //default return value (no sorting)
    //       });

    //       //this.healthProfessionals.results.reverse();
    //       if (this.data.prof_id) {
    //         const selectProf = this.healthProfessionals.results.find((prof) => prof.health_professional_id === this.data.prof_id);
    //         if (selectProf) {

    //           this.selectedProfessional = selectProf;
    //           this.editModel.health_professional_id = selectProf.health_professional_id;
    //         }
    //       } else {
    //         const selectProf = this.healthProfessionals.results.find((prof) => prof.health_professional_id === this.editModel.health_professional_id);
    //         if (selectProf) {

    //           this.selectedProfessional = selectProf;
    //           this.editModel.health_professional_id = selectProf.health_professional_id;
    //         }

    //       }
    //       this.loading = false;
    //     });
    // }

    if (this.data.description.periods) {
      this.data.description.periods.map((period) => {
        this.periods.results.push({ name: period, id: period });
      });
    }
    if (this.data.description.escalas) {
      this.gradeData.results = this.data.description.escalas;
      //this.gradeData.results.push({ name: "Selecione a Grade", id: 0 });
    }

    var initialDate = new Date();

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };
  }
  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.editModel = { ...this.data.description };
  }

  createRegister() {
    if (
      this.selectedUnit &&
      this.modelDataInicio != 0 &&
      this.modelDataFim != 0
    ) {
      this.datainicial =
        new Date(this.modelDataInicio + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";
      this.dataFinal =
        new Date(this.modelDataFim + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";

      var parametros = new IHealthUnit();

      parametros.health_unit = this.selectedUnit.id;
      parametros.health_professional =
        this.selectedProfessional.health_professional_id;
      if (this.datainicial)
        parametros.adjusted_start_datetime = this.datainicial;
      if (this.dataFinal) parametros.adjusted_end_datetime = this.dataFinal;
      parametros.disable_adjustment = "false";
      parametros.image = this.selectedProfessional.profile.photo;
      parametros.doctor = this.selectedProfessional.name;
      parametros.clinic = this.selectedUnit.name;
      parametros.modelDataInicio = this.modelDataInicio;
      parametros.modelDataFim = this.modelDataFim;

      this.dialogRef.close(parametros);
    } else {
      this.loading = false;
      this.openDialog("Necessário preencher os campos.");
    }
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  editManual() {
    var datavalida = new Date(
      this.editModel.manualentryDate + new Date().getTimezoneOffset() * 60000
    );
    var hoje = new Date();

    //if(datavalida.getFullYear() == hoje.getFullYear() && datavalida.getMonth() == hoje.getMonth()){

    this.datainicial = null;

    if (this.modelDataInicio) {
      this.datainicial =
        new Date(this.modelDataInicio + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";

      this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
    }
    this.dataFinal = null;
    if (this.modelDataFim) {
      this.dataFinal =
        new Date(this.modelDataFim + new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, 19) + "Z";

      this.dataFinal = this.dataFinal.substring(0, 17) + "00.000Z";
    }
    // let dateInicio = null;

    // if (this.editModel.entry !='__ /__ /____ __ : __') {
    //  const datas = this.editModel.entry.split(" ");
    // const data = datas[0];
    // const hora = datas[datas.length - 1]
    // const [dia, mes, ano] = data.split("/");
    // const newDate = ano + '/' + mes + '/' + dia + 'T' + hora + 'Z';
    //dateInicio =
    //   newDate

    //}

    var parametros = new IHealthUnit();

    parametros.health_unit = this.data.description.clinic_id;

    if (this.datainicial) parametros.adjusted_start_datetime = this.datainicial;

    // if(dateInicio){
    //   parametros.adjusted_start_datetime = dateInicio;
    // }
    if (this.dataFinal) parametros.adjusted_end_datetime = this.dataFinal;

    // if (dateInicio) parametros.adj usted_start_datetime = dateInicio;

    parametros.health_professional =
      this.selectedProfessional.health_professional_id;
    // if (this.selectedPeriod.name != "Selecione o periodo") {
    //   parametros.period = this.selectedPeriod.name;
    // }

    if (!this.selectedGrade) {
      this.selectedGrade = { name: "Selecione a Unidade", id: null };
    }

    if (this.selectedGrade.id != null) {
      parametros.grid_id = this.selectedGrade.id;
    }
    if (this.selectedUnit.id != null) {
      parametros.health_unit = this.selectedUnit.id;
    }
    //parametros.adjusted_start_datetime = this.datainicial ? this.datainicial : dateInicio;

    this.loading = true;
    this.consolidatedShiftService
      .editConsolidatedShift(parametros, this.editModel.id)
      .subscribe(
        (data) => {
          if (data.id != "") {
            if (this.data?.noShow != true)
              this.openDialogSucesso("Registro realizado com sucesso.");
            this.dialogRef.close("0");
          }

          this.loading = false;

          return true;
        },
        (error) => {
          this.loading = false;
          return error;
        }
      );

    // }else{

    //  this.openDialog("Data informada fora do período do mês vigente.")

    // }

    //editConsolidedShift
  }
  // And function editManual

  formatDate(data: string) {
    var array = data.split("");
    var year = array[0] + array[1] + array[2] + array[3];
    var month = array[5] + array[6];
    var day = array[8] + array[9];
    var hour = array[11] + array[12];
    var hourInt = parseInt(hour) - 3;

    if (hourInt < 0) {
      hourInt = hourInt + 24;
    }
    var hour = hourInt.toLocaleString();
    var minute = array[14] + array[15];
    return day + "/" + month + "/" + year + "  " + hour + ":" + minute;
  }
}
