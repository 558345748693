export class IHealthUnitProfesional{    
        health_unit_id: string;
        health_units_id: any[];
        start_datetime: string;
        end_datetime: string;
        id: string;
        name: string;
        health_professional_id: string;
        health_professionals_id: any[];
                
}