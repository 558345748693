
export class profileModel { 
    genre: string;
    marital_status: string;
    cel_phone: string;
    rg: string;
    cpf: string;
    date_of_birth: string;
    address: string;
    address_number: string;
    address_complement: string;
    city: string;
    state: string;
    zip_code: string;
    photo: string;
    occupation_area: string;
    regional_council_document_number: string;
    regional_council_document_picture: string;
    regional_council_state: string;
}