import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { IHealthUnit } from "../_models/health-unit.model";
import { Observable } from "rxjs";
import { IHourReportModel } from "../_models/hour-report.model";
import { iUnit } from "../_models/unit.model";
import { CheckModel } from "../_models/check.model";
import { cadModel } from "../_models/cad.model";
import { addHours } from "date-fns";
import { RequestUnit } from "../_interface/request-unit.interface";

@Injectable({
  providedIn: "root",
})
export class HealthUnitService {
  constructor(private http: HttpClient) {}

  public getHealthUnitsWeb(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "health-units/");
  }
  public getUnitById(unidade): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "health-units/" + unidade);
  }

  public getType(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "health-units-types/");
  }

  public addUnit(body) {
    return this.http.post<iUnit>(
      environment.apiUrl + "health-units-management/",
      body
    );
  }

  public getHealthUnits(params?: any): Observable<RequestUnit> {
    return this.http.get<RequestUnit>(
      environment.apiUrl + "health-units-management/",
      { params: params }
    );
  }

  public editUnit(body, id) {
    return this.http.patch<iUnit>(
      environment.apiUrl + "health-units-management/" + id + "/",
      body
    );
  }
  public getUnit(id): Observable<iUnit> {
    return this.http.get<iUnit>(
      environment.apiUrl + "health-units-management/" + id
    );
  }
}
