import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { UnitsModel } from "src/app/_models/units.model";
import { Router } from "@angular/router";
// import { IinviteProfessional } from "src/app/_models/inviteprofessional.model";
// import { Table } from "primeng/table";
import { cleanProperties } from "src/utils";
import { GetHealthProfessionalRequest } from "src/app/_models/health-professional.model";
import { ReportsService } from "src/app/_services/reports.service";
import { HttpClient } from "@angular/common/http";
import { HealthPointRecordService } from "../../_services/pointRecord.service";
import { DialogComponent } from "../dialog/dialog.component";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  addHours,
  addMinutes,
  format,
  getDate,
  getMonth,
  isAfter,
  isBefore,
  subHours,
  subMinutes,
  addDays,
  setDate,
  set,
  isLastDayOfMonth,
  lastDayOfMonth,
} from "date-fns";
import { ptBR } from "date-fns/locale";
import { MatDialog } from "@angular/material";
import { EditRegistroComponent } from "../editregistro/editregistro.component";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { ReviewService } from "src/app/_services/review.service";
import { GridService } from "src/app/_services/grid.service";
import { ScheduleService } from "src/app/_services/schedule.service";
import { ConsolidatedShiftService } from "../../_services/consolidated-shift.service";
import { WorkSlotService } from "../../_services/work-slot.service";
import { ScheduleMonitoringModalComponent } from "../schedule-monitoring-modal/schedule-monitoring-modal.component";
import { SCHEDULE_MONITORING_MODAL_TOKEN } from "../schedule-monitoring-modal/schedule-monitoring-modal.token";
import { ChangeDetectorRef } from "@angular/core";
import {
  DateTimeAdapter,
  OwlDateTimeIntl,
  OwlDateTimeComponent,
} from "@danielmoncada/angular-datetime-picker";

export class ConsolidatedShift {
  health_professional_name: string;
  start_datetime: Date;
  end_datetime: Date;
  worked_hours: Date;
}

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: "medilog-point-record",
  templateUrl: "./point-record.component.html",
  styleUrls: ["./point-record.component.scss"],
})
export class PointRecordComponent implements OnInit {
  isOpen = false;
  title: string;
  scheduleResult: any;
  selectedGrade: any = {
    name: "Selecione a Grade",
    id: null,
  };
  healthProfessionals: any = {
    results: [],
  };
  selectedProfessional: any = {
    name: "Todos os Profissionais",
    user_type: 0,
    email: 0,
    id: null,
  };
  loading: boolean = true;

  pt: any;
  gradeAllData: any;
  gradeData: any = {
    results: [],
  };
  apontamentoFora = false;
  apontamentoDentro = false;
  cols: any[];
  consolidatedShift = [];
  externalSchedule = [];
  externalConsolidatedShift: number = 0;
  schedule: any;
  showUnit: string = "Selecione a Unidade";
  showSchedule: string = "Selecione a Grade";
  showProfessional: string = "Todos os Profissionais";
  showMonth: string = "Selecione o Mês";
  countTurnos: any;
  gridId: any;
  realMonthText: string;
  realMonth: string;
  mesAtivo: number;
  yearSchedule: number;
  datesOfMonth: any;
  gradeHours: any;
  myInnerHeight: number;
  rowWeek: any;
  scheduleHistory: any;
  workSlotData: any;
  workSlot: any;
  showTable: boolean;
  report: any;
  resultSchedule: any;
  originalConsolidatedShift;
  greenProfessionals: any[] = [];
  modelDataInicio: any = set(new Date(), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  modelDataFim: any = set(new Date(), {
    date: getDate(lastDayOfMonth(new Date())),
    hours: 23,
    minutes: 59,
    seconds: 59,
  });

  constructor(
    private dateTimeAdapter: DateTimeAdapter<any>,
    private owlDateTimeIntl: OwlDateTimeIntl,
    public dialog: MatDialog,
    private healthPointRecordService: HealthPointRecordService,
    private reportsService: ReportsService,
    private http: HttpClient,
    private healthUnitService: HealthUnitService,
    private healthProfessionalService: HealthProfessionalsService,
    private reviewService: ReviewService,
    private gridService: GridService,
    private scheduleService: ScheduleService,
    private consolidatedShiftService: ConsolidatedShiftService,
    private workSlotService: WorkSlotService,
    private cdr: ChangeDetectorRef,
    @Inject(SCHEDULE_MONITORING_MODAL_TOKEN)
    private scheduleMonitoringModalComponent: TemplateRef<ScheduleMonitoringModalComponent>
  ) {
    document.getElementById("menu").click();
    this.dateTimeAdapter.setLocale("pt-BR");
    owlDateTimeIntl.setBtnLabel = "";
    owlDateTimeIntl.cancelBtnLabel = "";
  }

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  originalResult: any[] = [];

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;

  clinics: UnitsModel[];
  searchText: string = "";
  previous: string;
  pointRecord_: any = {};

  result: any[];
  searchKeyword;
  sort = 0;
  lastProperties = {
    limit: 10,
    offset: 0,
    end: null,
    start: null,
  };
  lenght = 0;
  healthUnits: any = null;
  selectedUnit: any = null;
  healthUnitsProfissionais = [];

  async ngOnInit() {
    this.title = localStorage.getItem("titulo");
    this.cols = [
      { field: "id", header: "#" },
      { field: "nome", header: "Nome" },
      { field: "unidade", header: "Unidade" },
      { field: "tempo não batido", header: "Tempo não batido" },
    ];
    await this.requestData();
    await this.getHealthUnits();

    await this.getProfessionals();
  }

  async requestData() {
    let inicialDate;
    let endDate;

    if (this.modelDataInicio != 0) {
      inicialDate = new Date(
        this.modelDataInicio + new Date().getTimezoneOffset() * 60000
      ).toISOString();
      inicialDate = inicialDate.substring(0, 17) + "00.000Z";
    }
    if (this.modelDataFim != 0) {
      endDate = new Date(
        this.modelDataFim + new Date().getTimezoneOffset() * 60000
      ).toISOString();
      endDate = endDate.substring(0, 17) + "59.000Z";
    }

    if (inicialDate) this.lastProperties.start = inicialDate;
    if (endDate) this.lastProperties.end = endDate;

    await this.getPointRecord(this.lastProperties);
  }

  async getPointRecord(event: any) {
    this.loading = true;
    this.lastProperties.offset = event?.first;
    const params: GetHealthProfessionalRequest = {
      limit: this.lastProperties.limit,
      offset: this.lastProperties.offset,
      search: this.searchText === "" ? null : this.searchText,
      health_unit: this.selectedUnit?.id ?? null,
      health_professional:
        this.selectedProfessional?.health_professional_id ?? null,
      start: this.lastProperties.start ?? null,
      end: this.lastProperties.end ?? null,
      last_check_in: 12,
    };

    await this.healthPointRecordService
      .getPointRecords(cleanProperties(params))
      .subscribe(
        (data) => {
          const pointRecord = [];

          this.result = data.results;
          this.lenght = data.count;
          this.loading = false;
        },
        (error) => {}
      );
  }

  minutesToHoursMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Formatting to ensure we have two digits for the minutes
    const formattedMinutes =
      remainingMinutes < 10
        ? `0${remainingMinutes}`
        : remainingMinutes.toString();
    return `${hours}:${formattedMinutes}h`;
  }

  disablePoint(id: string) {
    // this.openDialog("Deseja desativar profissional?", id, false);
    var texto = "Deseja finalizar esse registro?";
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto, enableCancel: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "0") {
        this.healthPointRecordService.finishPointRecord(id).subscribe(
          (res) => {
            this.openDialog("Registro finalizado com sucesso", true);
            this.requestData();
          },
          (error) => {
            this.openDialog("Erro ao finalizar registro");
          }
        );
      }
    });
  }

  openDialog(texto, sucess?: boolean): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: sucess ? "verdePanel" : "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getDate(date: string) {
    return this.formatDate(addHours(new Date(date), 3).toISOString());
  }
  formatDate(data: string) {
    if (data) {
      var array = data.split("");
      var year = array[0] + array[1] + array[2] + array[3];
      var month = array[5] + array[6];
      var day = array[8] + array[9];
      var hour = array[11] + array[12];

      var hourInt = parseInt(hour) - 3;

      if (hourInt < 0) {
        hourInt = hourInt + 24;
        day = (Number(day) - 1).toString();
      }
      var hour = hourInt.toLocaleString();

      var minute = array[14] + array[15];
      var sec = array[17] + array[18];

      return (
        (Number(day) <= 9 ? "0" + Number(day) : day) +
        "/" +
        month +
        "/" +
        year +
        " " +
        (Number(hour) <= 9 ? "0" + Number(hour) : hour) +
        ":" +
        minute +
        ":" +
        (Number(sec) <= 9 ? "0" + Number(sec) : sec)
      );
    }
  }

  async getHealthUnits() {
    this.loading = true;
    const params = {
      health_unit_status: "enable",
    };
    await this.healthUnitService
      .getHealthUnits(params)
      .subscribe(async (data) => {
        this.healthUnits = data;

        this.healthUnits.results.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        this.healthUnits.results.unshift({
          name: "Selecione a Unidade",
          id: null,
        });
        this.loading = false;
        //this.healthUnits.results.reverse();
        // await  this.getProfissionais();
      });

    this.selectedUnit = { name: "Selecione a Unidade", id: null };
  }

  async getProfessionals(
    occupation_area?: string,
    areas_of_expertise?: string[]
  ) {
    this.loading = true;
    if (occupation_area) {
      await this.healthProfessionalService
        .getCompanyHealthProfessionalsCategory(
          occupation_area,
          areas_of_expertise
        )
        .subscribe(async (data) => {
          this.healthProfessionals = data;

          await this.healthProfessionals.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.healthProfessionals.results.map((professional) => {
            this.healthUnitsProfissionais.push(professional);
          });

          this.healthProfessionals.results.unshift({
            name: "Todos os Profissionais",
            user_type: 0,
            email: 0,
            id: null,
          });

          // if (this.selectedGrade.id) {
          //   this.requestData()
          // }
          this.loading = false;
        });
    } else {
      await this.healthProfessionalService
        .getCompanyHealthProfessionalsCategory()
        .subscribe((data) => {
          this.healthProfessionals = data;

          this.healthProfessionals.results.sort(function (a, b) {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          this.healthProfessionals.results.map((professional) => {
            this.healthUnitsProfissionais.push(professional);
          });

          this.healthProfessionals.results.unshift({
            name: "Todos os Profissionais",
            user_type: 0,
            email: 0,
            id: null,
          });
          this.loading = false;
        });
    }
  }

  chosenDateHandler(datepicker: OwlDateTimeComponent<any>, input: string) {
    if (input === "ATE") {
      this.modelDataFim = datepicker?.selected;
    } else {
      this.modelDataInicio = datepicker?.selected;
    }
  }
}
