export class AddressModel{    
    nome: string;
    tipo: string;
    responsavel: string;
    unidade: string;
    telefone: string;
    celular: string;
    email: string;
    cep: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    estado: string;
            
}