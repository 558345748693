import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Company } from "src/app/_models/company.model";
import { CompanyService } from "src/app/_services/company.service";
import { uuid4 } from "src/utils";
import { DialogComponent } from "../dialog/dialog.component";
declare var AzureStorage: any;

@Component({
  selector: "medilog-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.css"],
})
export class CompanyComponent implements OnInit {
  picImg: any;
  lblImg: any;
  picImgName: string;
  title: string;
  blobService: any;
  loading = false;
  form: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    short_name: new FormControl("", [
      Validators.required,
      Validators.maxLength(15),
    ]),
    logo_url: new FormControl("", Validators.required),
  });

  constructor(
    private companyService: CompanyService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.blobService = AzureStorage.Blob.createBlobService(
      "DefaultEndpointsProtocol=https;AccountName=mediclog;AccountKey=6GYe4m9oPynRROntIhDoGfdnur4HhQlYg8+8oDzRDlyzd13KLmbRzmSw2q67QAtQBX3x+WO8CYS9ti8MXHca4g==;EndpointSuffix=core.windows.net"
    );
    this.blobService.listContainersSegmented(null, function (error, results) {
      if (error) {
        this.openDialogError(
          "Não foi possível fazer o upload, tente novamente"
        );
      } else {
        for (var i = 0, container; (container = results.entries[i]); i++) {}
      }
    });

    this.lblImg = "Foto da empresa";
    this.title = localStorage.getItem("titulo");

    await this.getCompany();
  }
  async getCompany(change?: boolean) {
    this.loading = true;
    await this.companyService.getCompany().subscribe(
      async (res) => {
        if (res.logo_url != "" && res.logo_url != null) {
          this.form.controls.logo_url.setValue(res.logo_url);
        } else {
          this.form.controls.logo_url.setValue(
            "https://mediclog.blob.core.windows.net/images/58c185e5-5a2c-4760-98c8-04266cfbe3c4.jpg"
          );
        }
        https: this.form.controls.name.setValue(res.name);
        this.form.controls.short_name.setValue(res.short_name);
        this.loading = false;
      },
      (error) => {
        this.openDialogError(
          "Não foi possível buscar a empresa, tente novamente"
        );
        this.loading = false;
      }
    );
  }

  onFileInput(event) {
    this.picImg = event.srcElement.files;
    this.lblImg = event.srcElement.files[0].name;
    this.picImgName = uuid4() + ".jpg";

    var customBlockSize =
      this.picImg.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    this.blobService.singleBlobPutThresholdInBytes = customBlockSize;

    this.blobService.createBlockBlobFromBrowserFile(
      "images",
      this.picImgName,
      event.srcElement.files[0],
      { blockSize: customBlockSize },
      function (error, result, response) {
        if (error) {
          this.openDialogError(
            "Não foi possível fazer o upload, tente novamente"
          );
          this.picImg = null;
          this.picImgName = null;
          this.lblImg = "Foto da empresa";
        }
      }.bind(this)
    );
  }

  async saveCompany() {
    this.loading = true;

    const body = {
      ...this.form.getRawValue(),
      logo_url: this.picImgName
        ? "https://mediclog.blob.core.windows.net/images/" + this.picImgName
        : this.form.controls.logo_url.value,
    };
    await this.companyService.updateCompany(body).subscribe(
      async (res) => {
        this.loading = false;
      },
      (error) => {
        this.openDialogError(
          "Não foi possível salvar a empresa, tente novamente"
        );
        console.log(error);
        this.loading = false;
      }
    );
  }
  openDialogError(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
