import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getInfoNumberCompany(): Observable<any> {
    return this.http.get<any>(
      environment.apiUrl + "dashboard/company-summary/"
    );
  }

  public getInfoNumber(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + "dashboard/company-status/");
  }
}
