import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { cleanProperties } from "src/utils";

export class ExistUser {
  exists: boolean;
}
@Injectable({
  providedIn: "root",
})
export class UserInfoService {
  constructor(private http: HttpClient) {}

  public changeProfessionalInfo(id, data): Observable<any> {
    return this.http.patch<any>(
      environment.apiUrl + "user-info/" + id + "/",
      data
    );
  }
  public getInfo(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "user-info/");
  }
  public checkExistUser(cpf: string, email?: string): Observable<ExistUser> {
    const body = {
      cpf: cpf,
      email: email ?? null,
    };
    return this.http.post<ExistUser>(
      environment.apiUrl + "user-info/exists/",
      cleanProperties(body)
    );
  }
}
