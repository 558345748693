import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InvitationsService {
  constructor(private http: HttpClient) {}

  public showInvites(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "invitations/");
  }

  public renewInvite(id): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + "invitations/" + id);
  }

  public delete(id) {
    return this.http.delete<any>(
      environment.apiUrl + "invitations/" + id + "/"
    );
  }

  public sendInvite(body) {
    return this.http.post<any>(environment.apiUrl + "invitations/", body);
  }
}
