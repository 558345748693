import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IHealthPointRecord } from "./../_models/health-pointRecord.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { cleanProperties } from "src/utils";
import { HealthPointProf, HealthUnitsResponse } from "../_models/point-record";

@Injectable({
  providedIn: "root",
})
export class HealthPointRecordService {
  constructor(private http: HttpClient) {}

  public getPointRecords(params): Observable<any> {
    return this.http.get<any>(environment.apiUrl + "pending-checks/", {
      params: cleanProperties(params),
    });
  }

  public finishPointRecord(id) {
    return this.http.post(
      environment.apiUrl + "pending-checks/" + id + "/finish/",
      {}
    );
  }

  public getPointRecordUnits(): Observable<HealthUnitsResponse> {
    return this.http.get<HealthUnitsResponse>(
      environment.apiUrl + "pending-checks/"
    );
  }

  public getPointRecordProfessionals(): Observable<HealthPointProf> {
    return this.http.get<HealthPointProf>(
      environment.apiUrl + "pending-checks/"
    );
  }

  // public getPointRecordsSearch(Id:String, Name: String, Health_unit: String): Observable<any> {
  //   const params = {
  //     id: Id,
  //     name: Name,
  //     health_unit: Health_unit
  //   }
  //   return this.http.get<any>(environment.apiUrl + "pending-checks/",{
  //     params: cleanProperties(params)
  //   });
  // }

  // public getPointRecordsSearch(params?: any): Observable<any> {
  //   return this.http.get<any>(environment.apiUrl + "pending-checks/",{
  //     params: params,
  //   });
  // }

  // public getPointRecords(id): Observable<any[]> {
  //   const apiRoute = "/pending-checks_list/";
  //   const apiUrl_ = "https://staging-medlog-api.herokuapp.com/#operation";
  //   return this.http.get<any[]>(`${apiUrl_}${apiRoute}`);
  // }

  // public getPointRecords(id): Observable<any> {
  //   const apiRoute = "https://staging-medlog-api.herokuapp.com/#operation";
  //   return this.http.get<any>(
  //     apiRoute + "/pending-checks_list/"
  //   );
  // }
}
