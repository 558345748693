import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { DialogData } from "../dialog/dialog.component";
import { dateFormat } from "src/utils";

@Component({
  selector: "medilog-schedule-monitoring-modal",
  templateUrl: "./schedule-monitoring-modal.component.html",
  styleUrls: ["./schedule-monitoring-modal.component.scss"],
})
export class ScheduleMonitoringModalComponent implements OnInit {
  loading = true;
  externalSchedule = [];
  listReviews = [];
  constructor(
    public dialogRef: MatDialogRef<ScheduleMonitoringModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      externalSchedule: Object[];
    }
  ) {}

  ngOnInit(): void {
    this.loading = false;

    if (this.data.externalSchedule) {
      this.listReviews = this.data.externalSchedule;
    }
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  dateFormatLocal(date?: string) {
    return dateFormat(date);
  }
  changeProf(consolidatedShift) {
    this.dialogRef.close({ ...consolidatedShift, action: "CHANGE" });
  }
  openEditDialog(consolidatedShift) {
    this.dialogRef.close({ ...consolidatedShift, action: "EDIT" });
  }
  removeRegister(consolidatedShift) {
    this.dialogRef.close({ ...consolidatedShift, action: "REMOVE" });
  }
}
