import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { startWith, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { IClinicReportModel } from "src/app/models/clinic-report.model";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { IHealthUnit } from "src/app/_models/health-unit.model";
import { formatDate } from "@angular/common";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";

import {
  subDays,
  setDate,
  lastDayOfMonth,
  getDate,
  set,
  isDate,
} from "date-fns";
import {
  OwlDateTimeIntl,
  DateTimeAdapter,
  OwlDateTimeComponent,
} from "@danielmoncada/angular-datetime-picker";
import { ReportsService } from "src/app/_services/reports.service";

const ELEMENT_DATA: IClinicReportModel[] = [];

@Component({
  selector: "medlog-clinic-report",
  templateUrl: "./clinic-report.component.html",
  styleUrls: ["./clinic-report.component.scss"],
})
export class ClinicReportComponent implements OnInit {
  constructor(
    public dateTimeAdapter: DateTimeAdapter<any>,
    public owlDateTimeIntl: OwlDateTimeIntl,
    private healthUnitService: HealthUnitService,
    private reportsService: ReportsService,
    public dialog: MatDialog
  ) {
    document.getElementById("menu").click();
    this.dateTimeAdapter.setLocale("pt-BR");

    owlDateTimeIntl.setBtnLabel = "";
    owlDateTimeIntl.cancelBtnLabel = "";
  }

  myControl = new FormControl();
  results: any[];
  text: string;
  final: any;
  initial: any;
  showTable: boolean = false;
  cols: any[];
  totalHours;
  healthUnits;
  reportUnitis;
  selectedUnit;
  datainicial;
  dataFinal;
  datainicialFormatada;
  datafinalFormatada;
  loading = true;
  modelDataInicio: any = set(new Date(), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  modelDataFim: any = set(new Date(), {
    date: getDate(lastDayOfMonth(new Date())),
    hours: 23,
    minutes: 59,
    seconds: 59,
  });

  minDateValue;

  filteredOptions: Observable<string[]>;
  matAutoComplete: string;
  public unitFilter: IHealthUnit;
  public csvFilter: IHealthUnit;

  clinics: IClinicReportModel[] = [];
  pt: any;
  title;

  ngOnInit() {
    this.title = localStorage.getItem("titulo");

    const params = {
      health_unit_status: "enable",
    };

    this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: -1 });
      this.loading = false;
    });
    this.selectedUnit = { name: "Todas as Unidades", id: -1 };
    var initialDate = new Date();
    this.initial = initialDate;
    this.totalHours = 0;

    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };
  }

  addDateCalendar() {
    this.minDateValue = new Date(this.modelDataInicio);
    this.minDateValue.setDate(this.minDateValue.getDate() + 30);
  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }
  chosenDateHandler(event: any, input: string) {
    if (input === "ATE") {
      this.modelDataFim = event?.selected;
    } else {
      this.modelDataInicio = event?.selected;
    }
  }
  formatDate(data: Date) {
    var newMonth;
    var newDay;
    var novaData = new Date(data);
    var year = novaData.getFullYear();
    var month = novaData.getMonth() + 1;
    var day = novaData.getDay();
    var hour = novaData.getHours();
    var minute = novaData.getMinutes();

    if (month < 10) {
      newMonth = "0" + month;
    } else {
      newMonth = month;
    }

    if (day < 10) {
      newDay = "0" + day;
    } else {
      newDay = day;
    }

    return (
      year + "-" + newMonth + "-" + newDay + "T" + hour + ":" + minute + ":00Z"
    );
  }

  formatDatePT(data: Date) {
    return data.toLocaleDateString();
  }

  timestrToSec(timestr) {
    var parts = timestr.split(":");
    return parts[0] * 3600 + parts[1] * 60 + +parts[2];
  }

  pad(num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return "" + num;
    }
  }

  formatTime(seconds) {
    return [
      this.pad(Math.floor(seconds / 3600)),
      this.pad(Math.floor(seconds / 60) % 60),
      this.pad(seconds % 60),
    ].join(":");
  }

  reportClinic() {
    this.clinics = [];
    this.loading = true;
    var times = 0;

    if (this.modelDataInicio != 0 && this.modelDataFim != 0) {
      this.datainicial = new Date(
        this.modelDataInicio + new Date().getTimezoneOffset() * 60000
      ).toISOString();
      this.dataFinal = new Date(
        this.modelDataFim + new Date().getTimezoneOffset() * 60000
      ).toISOString();
      this.unitFilter = new IHealthUnit();

      if (this.selectedUnit.id == -1) {
        this.unitFilter.health_units_id = [];
      } else {
        this.unitFilter.health_units_id = [this.selectedUnit.id];
      }
      this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
      this.dataFinal = this.dataFinal.substring(0, 17) + "59.000Z";

      this.unitFilter.end_datetime = this.dataFinal;
      this.unitFilter.start_datetime = this.datainicial;

      this.cols = [
        { field: "clinic", header: "Unidade" },
        { field: "qtd", header: "Quantidade" },
        { field: "date", header: "Período" },
        { field: "hours", header: "Tempo" },
      ];

      this.reportsService.reportUnits(this.unitFilter).subscribe(
        (data) => {
          return new Promise(
            function (resolve, reject) {
              this.loading = false;
              this.reportUnitis = data;
              var aPromises = [];

              if (this.reportUnitis.length == 0) {
                this.openDialog("Não foram encontrados registros");
                resolve();
              } else {
                for (let i = 0; i < this.reportUnitis.length; i++) {
                  aPromises.push(
                    new Promise(
                      function (resolves, reject) {
                        if (
                          this.reportUnitis[i].total_worked_hours != "00:00:00"
                        ) {
                          times =
                            Number(times) +
                            Number(
                              this.timestrToSec(
                                this.reportUnitis[i].total_worked_hours
                              )
                            );
                        }

                        let cli = {
                          clinic: this.reportUnitis[i].health_unit_name,
                          qtd: this.reportUnitis[i]
                            .health_professionals_quantity,
                          date:
                            new Date(
                              this.reportUnitis[i].start_datetime
                            ).toLocaleString() +
                            " - " +
                            new Date(
                              this.reportUnitis[i].end_datetime
                            ).toLocaleString(),
                          hours: this.reportUnitis[i].total_worked_hours,
                        };
                        this.clinics.push(cli);
                        resolves();
                      }.bind(this)
                    )
                  );
                }

                Promise.all(aPromises).then(
                  function () {
                    resolve();
                    this.totalHours = this.formatTime(times);
                  }.bind(this)
                );
              }

              this.showTable = true;
              return true;
            }.bind(this)
          );
        },
        (error) => {
          this.loading = false;
          this.openDialog("Ocorreu um erro, tente novamente!");
          return error;
        }
      );
    } else {
      this.loading = false;
      this.openDialog(
        "Necessário preencher os campos de data inicial e data final."
      );
    }
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  search(event) {
    this.reportClinic();
    //this.results = this.healthUnits.results
  }

  exportar() {
    this.csvFilter = new IHealthUnit();
    this.datainicial = new Date(
      this.modelDataInicio + new Date().getTimezoneOffset() * 60000
    ).toISOString();

    this.dataFinal = new Date(
      this.modelDataFim + new Date().getTimezoneOffset() * 60000
    ).toISOString();

    this.csvFilter.health_units_id = [this.selectedUnit.id];
    this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
    this.dataFinal = this.dataFinal.substring(0, 17) + "59.000Z";
    this.csvFilter.start_datetime = this.datainicial;
    this.csvFilter.end_datetime = this.dataFinal;

    this.loading = true;

    this.reportsService.reportUnityCSV(this.csvFilter).subscribe(
      (data) => {
        this.loading = false;
        window.open(data["report_file"]);

        return true;
      },
      (error) => {
        this.loading = false;
        return error;
      }
    );
  }

  exportarPDF() {
    this.csvFilter = new IHealthUnit();
    this.datainicial = new Date(
      this.modelDataInicio + new Date().getTimezoneOffset() * 60000
    ).toISOString();
    this.dataFinal = new Date(
      this.modelDataFim + new Date().getTimezoneOffset() * 60000
    ).toISOString();
    this.csvFilter.health_units_id = this.selectedUnit.id;
    this.datainicial = this.datainicial.substring(0, 17) + "00.000Z";
    this.dataFinal = this.dataFinal.substring(0, 17) + "59.000Z";
    this.csvFilter.start_datetime = this.datainicial;
    this.csvFilter.end_datetime = this.dataFinal;

    this.loading = true;

    this.reportsService.reportUnityPDF(this.csvFilter).subscribe(
      (data) => {
        this.loading = false;
        window.open(data["report_file"]);

        return true;
      },
      (error) => {
        this.loading = false;
        return error;
      }
    );
  }
}
