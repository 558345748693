import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IHealthProfessional } from "../_models/health-professional.model";
import { HttpClient } from "@angular/common/http";

import { RequestProfessionals } from "../_interface/request-professionals.interface";
import { cleanProperties } from "src/utils";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class HealthProfessionalsService {
  [x: string]: any;
  constructor(private http: HttpClient) {}

  public getHealthProfessionals(
    params?: any
  ): Observable<RequestProfessionals> {
    return this.http.get<RequestProfessionals>(
      environment.apiUrl + "health-professionals/",
      { params: params }
    );
  }

  public getHealthProfessionalsByCategory(
    id_category?: string
  ): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl +
        "health-professionals/?occupation_area_id=" +
        id_category
    );
  }

  public getCompanyHealthProfessionalsCategory(
    id_category?: string,
    areas_of_expertise?: string[],
    search?: string
  ): Observable<any[]> {
    let request: any = {
      occupation_area_id: null,
    };

    if (id_category) {
      request.occupation_area_id = id_category;
    }

    if (areas_of_expertise?.length) {
      request.areas_of_expertise = areas_of_expertise;
    }
    if (search) request.search = search;
    return this.http.get<any[]>(
      environment.apiUrl + "company-health-professionals/",
      {
        params: cleanProperties(request),
      }
    );
  }

  public getHealthProfessionalsById(id): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl + "health-professionals/" + id + "/"
    );
  }

  public updateProf(body, id) {
    return this.http.patch<IHealthProfessional>(
      environment.apiUrl + "health-professionals/" + id + "/",
      body
    );
  }

  public getProfessionalUnits(latitude, longitude, radius): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl +
        "maps/?latitude=" +
        latitude +
        "&longitude=" +
        longitude +
        "&radius=" +
        radius +
        ""
    );
  }
  public getProfessionals(id): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl + "health-professionals/" + id
    );
  }
  public disableProfessional(id) {
    return this.http.put(
      environment.apiUrl + "health-professionals/" + id + "/disable",
      {}
    );
  }
  public enableProfessional(id) {
    return this.http.put(
      environment.apiUrl + "health-professionals/" + id + "/enable",
      {}
    );
  }
}
