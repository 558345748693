import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Company } from "../_models/company.model";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getCompany(): Observable<Company> {
    return this.http.get<Company>(environment.apiUrl + "company/");
  }

  updateCompany(company: Company): Observable<Company> {
    return this.http.patch<Company>(environment.apiUrlV2 + "company/", company);
  }

  changeCompanyImg(file: File): Observable<Company> {
    const formData = new FormData();
    formData.append("logo", file, file.name);
    formData.append(
      "metadata",
      JSON.stringify({ description: "Uploaded Image" })
    );
    // formData.append("Content-Type", "multipart/form-data");

    return this.http.patch<Company>(
      environment.apiUrl + "company/",
      formData,
      {}
    );
  }
}
