import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { DialogData, DataHistory } from "../dialog/dialog.component";

@Component({
  selector: "medilog-schedule-history",
  templateUrl: "./schedule-history.component.html",
  styleUrls: ["./schedule-history.component.scss"],
})
export class ScheduleHistoryComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ScheduleHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataHistory,
    private router: Router
  ) {}

  ngOnInit() {}
}
