export class IHealthProfessional {
  id: string;
  health_professional_email: string;
  name: string;
  user_type: string;
  action: string;
  health_unit: string;
  enabled?: boolean;
  profile?: any;
  email?: string;
  member_since?: string;
}

export interface GetHealthProfessionalRequest {
  last_check_in?: number;
  limit: number;
  offset: number;
  search: string;
  health_unit?: string;
  health_professional?: string;
  filters?: string;
  start?: string;
  end?: string;
}

export interface GetHealthProfessionalReportsRequest {
  report_type: string;
}

export interface GetHealthProfessionalReportsResponse {
  file_url: string;
}
