import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CitiesService {
  constructor(private http: HttpClient) {}
  public getCities(estado: string): Observable<any[]> {
    return this.http.get<any[]>(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados/" +
        estado +
        "/municipios"
    );
  }
}
