import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  Inject,
} from "@angular/core";
import { getMonth, subMinutes, isAfter } from "date-fns";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { MatDialog } from "@angular/material";
import { DialogComponent, History } from "../dialog/dialog.component";
import { ModalService } from "src/app/_services/modal.service";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ScheduleInsertComponent } from "../schedule-insert/schedule-insert.component";
import { ScheduleHistoryComponent } from "../schedule-history/schedule-history.component";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { cleanProperties } from "src/utils";
import { GridService } from "src/app/_services/grid.service";
import { ScheduleService } from "src/app/_services/schedule.service";
import { WorkSlotService } from "src/app/_services/work-slot.service";
export interface DialogData {
  gradeAllData: [];
  enableCancel: boolean;
  diasGrade: [];
  mesSelecionado: string;
  mesTexto: number;
  linha: string;
  position: string;
  uniqueDay: boolean;
  selectedUnit: string;
  selectedGrade: string;
  modelDataInicio: string;
}

@Component({
  selector: "medlog-schedule-table",
  templateUrl: "./schedule-table.component.html",
  styleUrls: ["./schedule-table.component.scss"],
})
export class ScheduleTableComponent implements OnInit {
  createdDate: any;
  updatedData: any;
  work_slots: any = null;
  workSlot: any = null;
  workSlotData: any = null;
  dataProfessionals: number;
  isNullDay: boolean;
  scheduleHistory: History[];
  edited: boolean;
  schedules: any;
  isOverlayVisible: boolean = false;

  constructor(
    private router: Router,
    private render: Renderer2,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private healthProfessionalService: HealthProfessionalsService,
    private gridService: GridService,
    private scheduleService: ScheduleService,
    private workSlotService: WorkSlotService
  ) {}
  datesProf = [];
  selectedProf;
  text: string;
  initial: any;
  healthUnits;
  selectedUnit: any = { id: null };
  loading = false;
  gradeData;
  selectedGrade;
  visualizarData = [];
  selectedVisualizar;
  gradeAllData = null;
  title;
  dialogs: DetailRegistroComponent;
  allGrid;
  gradeSchedule: any;
  datesOfMonth = [];
  month;
  realMonth;
  yearSchedule;
  realMonthText;
  countTurnos = 0;
  turnos;
  gradeHours = [];
  profsSelected = [];
  profState = [];
  profsCount = [];
  linha;
  position;
  myInnerHeight = 40;
  rowWeek;
  rowWeekCopy;
  gridId;
  mesAtivo;
  schedule;
  healthUnitsProfissionais = [];
  isSelectedVerify = false;
  teamProfessional = [];
  periodoTodo = false;
  selecaoManual = false;
  scheduleHistoryId;
  dataWorkSlots;
  arrayToPost;
  current_grid;
  healthTeamId: string;
  async ngOnInit() {
    this.teamProfessional = [];
    this.title = localStorage.getItem("titulo");
    this.route.queryParamMap.subscribe(async (params: any) => {
      this.month = params.params.month;
      this.current_grid = params.params.current_grid;
      this.gradeSchedule = JSON.parse(params.params.gradeSchedule);
    });

    this.route.params.subscribe(async (params) => {
      let id = params["id"];
      let schedule = params["schedule"];
      this.schedule = schedule;
      if (id) {
        await this.getGridId(id);
      }
    });
  }
  async getWorkSlot(schedule: string) {
    // const data = {
    //   schedule_id: schedule,
    // };
    // this.healthProfessionalService
    //   .getScheduleById(this.schedule)
    //   .subscribe(async (data) => {
    //     // this.loading = false;
    //     await this.checkDaysInCalendar();
    //     await  this.alterDate();
    //     // if (this.healthUnitsProfissionais.length === 0) {
    //     //   this.loading = true;
    //     //   this.getWorkSlot(this.schedule);
    //     // }
    //   })
  }
  async getGridId(id) {
    this.loading = true;

    await this.gridService.getGrid(id).subscribe(async (data) => {
      console.log(data, "data");
      this.gradeAllData = data;
      if (this.current_grid && this.gradeAllData.history?.length > 0) {
        const findGrid = this.gradeAllData.history.find(
          (grid) => grid.id === this.current_grid
        );
        if (findGrid) {
          this.gradeAllData = { ...findGrid, id: this.gradeAllData.id };
        }
      }
      this.selectedUnit.id = this.gradeAllData.health_unit;
      this.countTurnos = this.gradeAllData.planned_workshifts.length;

      // if(this.gradeAllData.areas_of_expertise.length){
      //   areas_of_expertise = this.gradeAllData.areas_of_expertise.map((value)=> value.id);
      // }

      await this.getProfissionaisCategory(
        this.gradeAllData.occupation_area,
        this.gradeAllData.areas_of_expertise_id ?? null
      );
      if (this.current_grid && this.gradeAllData.history?.length > 0) {
        const findGrid = this.gradeAllData.history.find(
          (grid) => grid.id === this.current_grid
        );
        if (findGrid) {
          this.gradeAllData = { ...findGrid, id: this.gradeAllData.id };
        }
      }
      this.selectedUnit.id = this.gradeAllData.health_unit;
      this.countTurnos = this.gradeAllData.planned_workshifts.length;

      // if(this.gradeAllData.areas_of_expertise.length){
      //   this.gradeAllData.areas_of_expertise = this.gradeAllData.areas_of_expertise.map((value)=> value.id);
      // }

      // await this.getProfissionaisCategory(
      //   this.gradeAllData.occupation_area,
      //   this.gradeAllData.areas_of_expertise
      // );
    });
  }

  async mountSchedule(idGrid) {
    const [ano, mes, dia] = this.month.split("-");
    const dateFormat = ano + "-" + mes + "-" + "01";

    await this.scheduleService
      .getScheduleById(this.gradeSchedule.id)
      .subscribe(async (data) => {
        // var filterData = data["results"].filter(
        //   (item) => item.id == this.schedule
        // );

        this.month = this.gradeSchedule.month;
        this.gridId = this.gradeSchedule.id;
        this.loading = false;
        this.createdDate = this.formatDate(this.gradeSchedule.created_at);
        this.updatedData = this.formatDate(this.gradeSchedule.updated_at);
        await this.mountTurno(
          this.gradeAllData.planned_workshifts,
          this.gradeSchedule.month
        );

        this.scheduleHistory = this.gradeSchedule.history_work_slots;
        this.workSlotData = this.gradeSchedule.work_slots;

        for (const team of this.gradeSchedule.health_team) {
          this.dataProfessionals = this.gradeSchedule.health_team.length;

          this.healthUnitsProfissionais.map((prof) => {
            // this.healthTeamId = team.id;
            if (prof.health_professional_id === team.health_professional) {
              if (
                this.teamProfessional.find(
                  (team) => team.id === prof.health_professional_id
                )
              ) {
                return;
              } else {
                this.teamProfessional.push({
                  id: prof.health_professional_id,
                  name: prof.name,
                  photo: prof.photo,
                  crm: prof.regional_council_document_number,
                  ativo: false,
                  periodo_todo: false,
                  selecao_manual: false,
                  profile: prof?.profile,
                });
              }
            }
          });
        }

        this.teamProfessional.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        await this.checkDaysInCalendar();
        await this.alterDate();
      });
  }
  teste(c) {}
  async alterDate() {
    this.workSlot = this.workSlotData;
    if (this.workSlot) {
      for (const slot of this.workSlot) {
        //O profissional para adicionar

        let prof = 
        
        slot?.health_professional?.id ?
        this.healthUnitsProfissionais.find(
          (profissional) =>
            profissional.health_professional_id === slot.health_professional.id
        ) : null;

        const dia = parseInt(slot.start_date.slice(slot.start_date.length - 2));

        for (var mes = 0; mes < this.rowWeek.length; mes++) {
          for (
            var semana = 0;
            semana < this.rowWeek[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              for (
                var profissional: number =
                  this.rowWeek[mes].semana[semana].dias[dias].professionais
                    .length - 1;
                profissional >= 0;
                profissional--
              ) {
                if (
                  prof &&
                  this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id === "" &&
                  this.rowWeek[mes].semana[semana].periodo === slot.period &&
                  !this.rowWeek[mes].semana[semana].dias[
                    dias
                  ].professionais.find(
                    (data) =>
                      data.health_professional_id ===
                        prof.health_professional_id ||
                      data.id === prof.health_professional_id
                  )
                ) {
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ] = {
                    id: prof.health_professional_id,
                    health_professional_id: prof.health_professional_id,
                    name: prof.name,
                    profile: prof.profile,
                    periodo: prof.periodo,
                    photo: prof.photo,
                    regional_council_document_number:
                      prof.regional_council_document_number,
                  };

                  if (
                    this.teamProfessional.find(
                      (team) => team.id === prof.health_professional_id
                    )
                  ) {
                    continue;
                  } else {
                    this.teamProfessional.push({
                      id: prof.health_professional_id,
                      name: prof.name,
                      photo: prof.photo,
                      crm: prof.regional_council_document_number,
                      ativo: false,
                      periodo_todo: false,
                      selecao_manual: false,
                      profile: prof.profile,
                    });
                  }
                  this.teamProfessional.sort(function (a, b) {
                    if (a.name > b.name) {
                      return 1;
                    }
                    if (a.name < b.name) {
                      return -1;
                    }
                    // a must be equal to b
                    return 0;
                  });
                  break;
                }
              }
            }
          }
        }

        this.teamProfessional.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    }
    this.loading = false;
  }

  async mountTurno(planned_workshifts, mes) {
    var mesPlanejado = new Date(mes.replace("-", "/").replace("-", "/"));
    await this.formatMonth(mesPlanejado.getMonth());
    await this.formatMonthText(mesPlanejado.getMonth());
    this.mesAtivo = mesPlanejado.getMonth();
    this.yearSchedule = mesPlanejado.getFullYear();
    this.datesOfMonth = await this.getWeeksInMonth(
      mesPlanejado.getFullYear(),
      mesPlanejado.getMonth(),
      planned_workshifts
    );

    let newDates = [];
    for (var i = 0; i < this.datesOfMonth[0].diasprof.length; i++) {
      newDates.push(this.datesOfMonth[0].diasprof[i].dia);
    }
    this.datesOfMonth[0].dates = newDates;

    const date = getMonth(mesPlanejado);

    this.rowWeek = await this.getWeeksInMonthNew(
      mesPlanejado.getFullYear(),
      date,
      planned_workshifts
    );

    await this.getWorkSlot(this.schedule);
    this.gradeHours = [];

    for (var s = 0; s < planned_workshifts.length; s++) {
      var starts = planned_workshifts[s].start_time.split(":");
      // if (Number(starts[0]) > 7 && Number(starts[0]) < 19) {
      //   //dia
      //   // let dataArray = {
      //   //   periodo: "DIURNO",
      //   //   periodoLabel: "07:00 - 19:00",
      //   //   grade: planned_workshifts[s],
      //   //   semana: this.datesOfMonth,
      //   //   proffesionals: [],
      //   // };

      //   var arrayDias = [];

      //   var height =
      //     40 * this.gradeAllData.planned_workshifts[0].professionals_count;
      //   this.myInnerHeight = height;

      //   for (
      //     var i = 0;
      //     i < this.gradeAllData.planned_workshifts[0].professionals_count;
      //     i++
      //   ) {
      //     arrayDias.push({ id: "", periodo: "diurno" });
      //     if (
      //       i ==
      //       this.gradeAllData.planned_workshifts[0].professionals_count - 1
      //     ) {
      //       dataArray.proffesionals = arrayDias;
      //       this.gradeHours.push(dataArray);
      //     }
      //   }
      // } else {
      //   //noite
      //   var arrayDias = [];
      //   let dataArray = {
      //     periodo: "NOTURNO",
      //     periodoLabel: "19:00 - 07:00",
      //     grade: planned_workshifts[s],
      //     semana: this.datesOfMonth,
      //     proffesionals: [],
      //   };

      //   for (
      //     var i = 0;
      //     i < this.gradeAllData.planned_workshifts[0].professionals_count;
      //     i++
      //   ) {
      //     arrayDias.push({ id: "", periodo: "noturno" });
      //     if (
      //       i ==
      //       this.gradeAllData.planned_workshifts[0].professionals_count - 1
      //     ) {
      //       dataArray.proffesionals = arrayDias;
      //       this.gradeHours.push(dataArray);
      //     }
      //   }
      // }

      if (s == planned_workshifts.length - 1) {
        // this.loading = true;

        this.workSlotService
          .getWorkSlotById(this.schedule)
          .subscribe((data) => {
            // this.loading = true;
            const schedule: any = data;

            // this.mountEditSchedule(schedule.history_work_slots);
          });
      }
    }
  }
  async getProf(event) {
    if (
      this.teamProfessional.find(
        (team) => team.id === event.value.health_professional_id
      )
    ) {
      return;
    } else {
      this.teamProfessional.push({
        id: event.value.health_professional_id,
        name: event.value.name,
        photo: event.value.photo,
        crm: event.value.regional_council_document_number,
        ativo: false,
        periodo_todo: false,
        selecao_manual: false,
        profile: event.value.profile,
      });
      this.isSelectedVerify = false;
    }
  }
  removeProfessionalTeam(event) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: "570px",
      height: "200px",
      data: {
        title: "Tem certeza que deseja continuar?",
        message:
          "Esta ação removerá o profissional dos dias em que está escalado.",
        type: "SCHEDULE",
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.teamProfessional = this.teamProfessional.filter(
          (professional) => professional.id != event.id
        );
        for (var mes = 0; mes < this.rowWeek.length; mes++) {
          for (
            var semana = 0;
            semana < this.rowWeek[mes].semana.length;
            semana++
          ) {
            for (
              var dias = 0;
              dias < this.rowWeek[mes].semana[semana].dias.length;
              dias++
            ) {
              for (
                var profissional = 0;
                profissional <
                this.rowWeek[mes].semana[semana].dias[dias].professionais
                  .length;
                profissional++
              ) {
                if (
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id === event.id
                ) {
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ] = {
                    id: "",
                  };
                }
              }
            }
          }
        }
      }
    });
    this.edited = true;
  }
  selectProfessionalEdit(event: any) {
    this.rowWeek[0].semana.map((turno) => {
      turno.ativo = false;
    });

    const professional = this.teamProfessional.map((professional) => {
      if (professional.id == event.id) {
        if (professional.ativo === true) {
          professional.ativo = false;
        } else {
          professional.ativo = true;
        }
      } else {
        if (professional.ativo === true) {
          professional.ativo = false;
        }
      }
    });
  }
  async selectTurnEdit(event, item) {
    this.loading = true;

    if (event.ativo === false) {
      await this.rowWeek[0].semana.map((turno) => {
        if (turno.id === event.id && turno.periodo === event.periodo) {
          turno.ativo = true;
        }
        if (!turno.diasAtivos) {
          turno.diasAtivos = [];
        }
      });

      await this.checkDaysInCalendar(item);
    } else {
      await this.rowWeek[0].semana.map((turno) => {
        if (turno.id === event.id && turno.periodo === event.periodo) {
          turno.ativo = false;
        }
      });
    }
    this.loading = false;
  }

  formatDate(data: string) {
    var array = data.split("");
    var year = array[0] + array[1] + array[2] + array[3];
    var month = array[5] + array[6];
    var day = array[8] + array[9];
    var hour = array[11] + array[12];
    var hourInt = parseInt(hour) - 3;

    if (hourInt < 0) {
      hourInt = hourInt + 24;
    }
    var hour = hourInt.toLocaleString();
    var minute = array[14] + array[15];
    return day + "/" + month + "/" + year + "  " + hour + ":" + minute;
  }

  async deletePosition(
    dayGrid,
    dia,
    positionDia,
    positionBlock,
    positionPeriodo,
    semana,
    diadia,
    prof,
    somenteDia
  ) {
    let limitDate = new Date(
      this.yearSchedule +
        "-" +
        this.realMonth +
        "-" +
        (Number(somenteDia) <= 9
          ? "0" + Number(somenteDia)
          : Number(somenteDia)) +
        "T" +
        dayGrid.realStartDate
    );

    limitDate = subMinutes(limitDate, -15);

    const dataAtual = new Date();

    if (!isAfter(limitDate, dataAtual)) {
      this.openDialog("Não é possível alterar essa data!");
      return;
    }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Tem certeza que deseja continuar?",
        message:
          "Esta ação removerá o profissional do dia em que está escalado.",
        type: "SCHEDULE",
      },
      width: "570px",
      height: "200px",
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        if (!this.rowWeek[0].semana[0].diasAtivos) {
        }
        // console.log(this.rowWeek[0].semana[0]);
        // this.rowWeek[0].semana[0].diasAtivos =
        //   this.rowWeek[0].semana[0].diasAtivos.filter(
        //     (d) => d.dia != dia && d.periodo != dayGrid.periodo
        //   );

        //Remove a cor azul do calendario lateral
        var elemento = document.getElementById(
          diadia + "-" + dayGrid.periodo + "-" + prof.id
        );
        if (elemento) {
          elemento.classList.remove("selected");
        }
        this.edited = true;
        dayGrid.dias[positionDia].professionais[positionPeriodo] = { id: "" };
      }
    });
  }

  addProfessional(
    dayGrid,
    dia,
    positionDia,
    positionBlock,
    positionPeriodo,
    semana
  ) {
    var unique = false;

    if (dia != "") {
      unique = true;
    }

    const dialogRef = this.dialog.open(ScheduleInsertComponent, {
      width: "600px",
      height: "290px",
      panelClass: "azulPanel",
      //disableClose: true,
      data: {
        uniqueDay: unique,
        gradeAllData: this.gradeAllData,
        diasGrade: this.datesOfMonth,
        mesSelecionado: this.realMonth,
        mesTexto: this.realMonthText,
        positionBlock: positionBlock,
        positionDia: positionDia,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (dia == "") {
          for (var sem = 0; sem < this.rowWeek.length; sem++) {
            for (
              var dias = 0;
              dias < this.rowWeek[sem].semana[positionPeriodo].dias.length;
              dias++
            ) {
              if (
                this.rowWeek[sem].semana[positionPeriodo].dias[dias].dia != 0
              ) {
                var filter = result.find(
                  (element) =>
                    element.dia ==
                    this.rowWeek[sem].semana[positionPeriodo].dias[dias].dia
                );

                if (filter) {
                  if (filter.isActive) {
                    const filter = this.rowWeek[sem].semana[
                      positionPeriodo
                    ].dias[dias].professionais.findIndex(
                      (element) => element.id == ""
                    );
                    this.rowWeek[sem].semana[positionPeriodo].dias[
                      dias
                    ].professionais[filter] = result[0]["profissional"];
                  }
                }
              }
            }
          }
        } else {
          this.rowWeek[semana].semana[positionPeriodo].dias[
            positionDia
          ].professionais[positionBlock] = result[0]["profissional"];
        }
      }
    });
  }
  selectAll(event) {
    for (var i = 0; i < event.dias.length; i++) {}
  }
  getNameProf(name: string) {
    const names = name.split(" ");
    if (names[0] === names[names.length - 1]) {
      return names[0];
    } else {
      return names[0] + " " + names[names.length - 1];
    }
  }

  selectedDate(event: any, dia, posicao, periodo, item) {
    let limitDate = new Date(
      this.yearSchedule +
        "-" +
        this.realMonth +
        "-" +
        (Number(dia) <= 9 ? "0" + Number(dia) : Number(dia)) +
        "T" +
        periodo.realStartDate
    );
    limitDate = subMinutes(limitDate, -15);

    const dataAtual = new Date();

    if (!isAfter(limitDate, dataAtual)) {
      this.openDialog("Não é possível alterar essa data!");
      return;
    }
    if (event.currentTarget.classList.contains("selected")) {
      //remove day in calendar
      this.render.removeClass(event.target, "selected");
      periodo.diasAtivos = periodo.diasAtivos.filter(
        (d) => d.dia != dia && d.periodo != periodo.periodo
      );

      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        for (
          var semana = 0;
          semana < this.rowWeek[mes].semana.length;
          semana++
        ) {
          for (
            var dias = 0;
            dias < this.rowWeek[mes].semana[semana].dias.length;
            dias++
          ) {
            for (
              var profissional: number =
                this.rowWeek[mes].semana[semana].dias[dias].professionais
                  .length - 1;
              profissional >= 0;
              profissional--
            ) {
              if (
                this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id === item.id &&
                this.rowWeek[mes].semana[semana].periodo === periodo.periodo
              ) {
                this.edited = true;
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ] = {
                  id: "",
                };
                return;
              }
            }
          }
        }
      }
    } else {
      //add day in calendar

      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        for (
          var semana = 0;
          semana < this.rowWeek[mes].semana.length;
          semana++
        ) {
          for (
            var dias = 0;
            dias < this.rowWeek[mes].semana[semana].dias.length;
            dias++
          ) {
            for (
              var profissional: number =
                this.rowWeek[mes].semana[semana].dias[dias].professionais
                  .length - 1;
              profissional >= 0;
              profissional--
            ) {
              if (
                this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id === "" &&
                this.rowWeek[mes].semana[semana].periodo === periodo.periodo
              ) {
                const search = this.rowWeek[mes].semana[semana].dias[
                  dias
                ].professionais.find((element) => element.id === item.id);
                this.edited = true;
                if (!search) {
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ] = {
                    id: item.id,
                    name: item.name,
                    photo: item.photo,
                    crm: item.crm,
                    periodo: periodo.periodo,
                    profile: item.profile,
                  };
                  this.render.addClass(event.target, "selected");
                  periodo.diasAtivos.push({
                    dia: dia,
                    id_profissional: item.id,
                    name: item.name,
                    photo: item.photo,
                    crm: item.crm,
                    periodo: periodo.periodo,
                    profile: item.profile,
                  });
                }
                return;
              }
              if (
                this.rowWeek[mes].semana[semana].dias[dias].dia === dia &&
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id != "" &&
                this.rowWeek[mes].semana[semana].periodo === periodo.periodo
              ) {
                var slotNotNull = true;
                this.loading = false;
              }
            }
          }
        }
      }
    }
    if (slotNotNull) {
      this.openDialog("Não há vagas disponíveis para este dia!");
      return;
    }
  }

  async checkDaysInCalendar(item?: any) {
    //Verifica os dias que já estão ocupados pelos profissionais

    for (var mes = 0; mes < this.rowWeek.length; mes++) {
      for (var semana = 0; semana < this.rowWeek[mes].semana.length; semana++) {
        for (
          var dias = 0;
          dias < this.rowWeek[mes].semana[semana].dias.length;
          dias++
        ) {
          for (
            var profissional: number =
              this.rowWeek[mes].semana[semana].dias[dias].professionais.length -
              1;
            profissional >= 0;
            profissional--
          ) {
            const result = this.rowWeek[mes].semana[semana].dias[
              dias
            ].professionais.filter((professional) => professional.id === "");

            if (result != null) {
              if (
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id != ""
              ) {
                const classElement =
                  this.rowWeek[mes].semana[semana].dias[dias].dia +
                  "-" +
                  this.rowWeek[mes].semana[semana].periodo +
                  "-" +
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id;

                setTimeout(function () {
                  var elemento = document.getElementById(classElement);

                  if (elemento) {
                    elemento.classList.add("selected");
                  }
                }, 10);
                if (!this.rowWeek[0].semana[0].diasAtivos) {
                  this.rowWeek[0].semana[0].diasAtivos = [];
                }
                this.rowWeek[0].semana[0].diasAtivos.push({
                  dia: this.rowWeek[mes].semana[semana].dias[dias].dia,
                  id_profissional:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id,
                  name: this.rowWeek[mes].semana[semana].dias[dias]
                    .professionais[profissional].name,
                  photo:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].photo,
                  crm: this.rowWeek[mes].semana[semana].dias[dias]
                    .professionais[profissional].crm,
                  periodo:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].periodo,
                  profile:
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].profile,
                });
              }
            }
          }
          const hasNull = this.rowWeek[mes].semana[semana].dias[
            dias
          ].professionais.find((professionais) => professionais.id === "");
          if (item) {
            var hasProfessional = this.rowWeek[mes].semana[semana].dias[
              dias
            ].professionais.find(
              (professionais) => professionais.id === item.id
            );
          }

          if (!hasNull && !hasProfessional && item) {
            const classElement =
              this.rowWeek[mes].semana[semana].dias[dias].dia +
              "-" +
              this.rowWeek[mes].semana[semana].periodo +
              "-" +
              item.id;

            setTimeout(function () {
              var elemento = document.getElementById(classElement);

              if (elemento) {
                elemento.classList.remove("startSelect");
                elemento.classList.remove("selected");
                elemento.classList.add("isNull");
              }
            }, 10);
          }
        }
      }
    }
  }

  getProfDia(dia, semana) {
    const filter = this.profsSelected.filter(
      (element) => Number(element.dia) == dia
    );
    if (filter.length > 0) {
      this.profState = filter[0];
      return "Ok";
    } else {
      return "";
    }
  }
  getDiaAtivo(dia, diaDia) {
    if (dia === 0 || dia === undefined || dia === null) {
      return this.gradeAllData.days_of_week.sunday;
    } else if (dia == "1") {
      return this.gradeAllData.days_of_week.monday;
    } else if (dia == "2") {
      return this.gradeAllData.days_of_week.tuesday;
    } else if (dia == "3") {
      return this.gradeAllData.days_of_week.wednesday;
    } else if (dia == "4") {
      return this.gradeAllData.days_of_week.thursday;
    } else if (dia == "5") {
      return this.gradeAllData.days_of_week.friday;
    } else {
      return this.gradeAllData.days_of_week.saturday;
    }
  }

  getDia(dia) {
    if (dia == "0") {
      return "Domingo";
    } else if (dia == "1") {
      return "Segunda";
    } else if (dia == "2") {
      return "Terça";
    } else if (dia == "3") {
      return "Quarta";
    } else if (dia == "4") {
      return "Quinta";
    } else if (dia == "5") {
      return "Sexta";
    } else {
      return "Sabado";
    }
  }

  formatDia(dia) {
    if (dia == 0) {
      return "";
    } else {
      if (dia < 10) {
        return "0" + dia.toString();
      } else {
        return dia;
      }
    }
  }

  formatMonth(mes) {
    if (mes == 0) {
      this.realMonth = "01";
    } else if (mes == 1) {
      this.realMonth = "02";
    } else if (mes == 2) {
      this.realMonth = "03";
    } else if (mes == 3) {
      this.realMonth = "04";
    } else if (mes == 4) {
      this.realMonth = "05";
    } else if (mes == 5) {
      this.realMonth = "06";
    } else if (mes == 6) {
      this.realMonth = "07";
    } else if (mes == 7) {
      this.realMonth = "08";
    } else if (mes == 8) {
      this.realMonth = "09";
    } else if (mes == 9) {
      this.realMonth = "10";
    } else if (mes == 10) {
      this.realMonth = "11";
    } else {
      this.realMonth = "12";
    }
  }

  formatMonthText(mes) {
    if (mes == 0) {
      this.realMonthText = "Janeiro";
    } else if (mes == 1) {
      this.realMonthText = "Fevereiro";
    } else if (mes == 2) {
      this.realMonthText = "Março";
    } else if (mes == 3) {
      this.realMonthText = "Abril";
    } else if (mes == 4) {
      this.realMonthText = "Maio";
    } else if (mes == 5) {
      this.realMonthText = "Junho";
    } else if (mes == 6) {
      this.realMonthText = "Julho";
    } else if (mes == 7) {
      this.realMonthText = "Agosto";
    } else if (mes == 8) {
      this.realMonthText = "Setembro";
    } else if (mes == 9) {
      this.realMonthText = "Outubro";
    } else if (mes == 10) {
      this.realMonthText = "Novembro";
    } else {
      this.realMonthText = "Dezembro";
    }
  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialogSucesso(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(["/home/escala/"]);
    });
  }
  openDialogSucessoSave(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  clearTable() {
    this.gradeAllData = null;
  }

  backEscala() {
    let selectedUnit;
    let selectedGrade;
    let modelDataInicio;

    this.route.queryParamMap.subscribe(async (params: any) => {
      selectedUnit = params.params["selectedUnit"];
      selectedGrade = params.params["selectedGrade"];
      modelDataInicio = params.params["modelDataInicio"];
    });
    this.router.navigate([
      "/home/schedule/",
      {
        selectedUnit,
        selectedGrade,
        modelDataInicio,
      },
    ]);
  }

  getWeeksInMonthNew(year, month, planned_workshifts) {
    const weeks = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate();

    let dayOfWeekCounter = firstDate.getDay();

    for (let date = 1; date <= numDays; date++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(date);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    var semanas = weeks
      .filter((w) => !!w.length)
      .map((w) => ({
        start: w[0],
        end: w[w.length - 1],
        dates: w,
      }));

    var firstWeek = semanas[0];
    var lastWeek = semanas[semanas.length - 1];
    var concatFirstWeek = 7 - firstWeek.dates.length;
    var concatLasttWeek = 7 - lastWeek.dates.length;

    var arrayWeek = [];

    for (var s = 0; s < semanas.length; s++) {
      arrayWeek.push({ semana: [] });
      for (var turno = 0; turno < this.countTurnos; turno++) {
        this.calculateHour(turno);

        const start_time = planned_workshifts[turno].start_time.substring(0, 5);
        const horaFim = this.somartempos(
          planned_workshifts[turno].start_time,
          planned_workshifts[turno].duration
        ).substring(0, 5);
        arrayWeek[s].semana.push({
          id: planned_workshifts[turno].id,
          periodo: planned_workshifts[turno].name,
          periodoLabel: start_time + "-" + horaFim,
          dias: [],
          realStartDate: planned_workshifts[turno].start_time,
          duration: planned_workshifts[turno].duration,
        });

        for (var dia = 0; dia < semanas[s].dates.length; dia++) {
          arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias.push({
            dia: semanas[s].dates[dia],
            professionais: [],
          });

          for (
            var prof = 0;
            prof < planned_workshifts[turno].professionals_count;
            prof++
          ) {
            if(this.getDiaAtivo(dia, null)){
              arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias[
                arrayWeek[s].semana[arrayWeek[s].semana.length - 1].dias.length -
                  1
              ].professionais.push({ id: "" });
            }
           
          }
        }
      }

      if (s == semanas.length - 1) {
        for (var i = 0; i < concatFirstWeek; i++) {
          for (var s = 0; s < arrayWeek[0].semana.length; s++) {
            arrayWeek[0].semana[s].dias.unshift({ dia: 0, professionais: [] });
          }
        }
        for (var i = 0; i < concatLasttWeek; i++) {
          for (var s = 0; s < arrayWeek[0].semana.length; s++) {
            arrayWeek[semanas.length - 1].semana[s].dias.push({
              dia: 0,
              professionais: [],
            });
          }
        }
        return arrayWeek;
      }
    }
  }

  getWeeksInMonth(year, month, planned_workshifts) {
    const weeks = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate();

    let dayOfWeekCounter = firstDate.getDay();

    for (let date = 1; date <= numDays; date++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(date);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    var tt = weeks
      .filter((w) => !!w.length)
      .map((w) => ({
        start: w[0],
        end: w[w.length - 1],
        dates: w,
      }));

    let arrayNew = [];
    for (var t = 0; t < this.countTurnos; t++) {
      var semanass = weeks
        .filter((w) => !!w.length)
        .map((w) => ({
          start: w[0],
          end: w[w.length - 1],
          dates: w,
        }));

      var firstWeek = tt[0];
      var lastWeek = tt[tt.length - 1];
      var concatFirstWeek = 7 - firstWeek.dates.length;
      var concatLasttWeek = 7 - lastWeek.dates.length;

      var starts = planned_workshifts[t].start_time.split(":");
      if (Number(starts[0]) > 7 && Number(starts[0]) < 19) {
        for (var a = 0; a < semanass.length; a++) {
          var asTurno = semanass;
          asTurno[a]["diasprof"] = [];
          for (var d = 0; d < asTurno[a].dates.length; d++) {
            asTurno[a]["diasprof"].push({
              profs: [],
              dia: asTurno[a].dates[d],
              periodo: "dia",
            });
            for (
              var pp = 0;
              pp < planned_workshifts[0].professionals_count;
              pp++
            ) {
              asTurno[a]["diasprof"][
                asTurno[a]["diasprof"].length - 1
              ].profs.push({ id: "" });
            }
          }
          if (a == asTurno.length - 1) {
            arrayNew.push(asTurno);
          }
        }
      } else {
        for (var i = 0; i < semanass.length; i++) {
          var abTurno = semanass;
          abTurno[i]["diasprof"] = [];
          for (var d = 0; d < abTurno[i].dates.length; d++) {
            abTurno[i]["diasprof"].push({
              profs: [],
              dia: abTurno[i].dates[d],
              periodo: "noite",
            });
            for (
              var pp = 0;
              pp < planned_workshifts[0].professionals_count;
              pp++
            ) {
              abTurno[i]["diasprof"][
                abTurno[i]["diasprof"].length - 1
              ].profs.push({ id: "" });
            }
          }
          if (i == abTurno.length - 1) {
            arrayNew.push(abTurno);
          }
        }
      }

      if (t == this.countTurnos - 1) {
        //return arrayNew;
      }
    }

    for (var i = 0; i < tt.length; i++) {
      var semanas = tt[i].dates;
      tt[i]["diasprof"] = [];
      for (var d = 0; d < tt[i].dates.length; d++) {
        tt[i]["diasprof"].push({ profs: [], dia: tt[i].dates[d] });
        for (var pp = 0; pp < planned_workshifts[0].professionals_count; pp++) {
          tt[i]["diasprof"][tt[i]["diasprof"].length - 1].profs.push({
            id: "",
          });
        }
      }

      if (i == tt.length - 1) {
        for (var i = 0; i < concatFirstWeek; i++) {
          firstWeek["diasprof"].unshift({ profs: [], dia: 0 });
        }
        for (var i = 0; i < concatLasttWeek; i++) {
          lastWeek["diasprof"].push({ profs: [], dia: 0 });
        }
        var dr = [];
        for (var pl = 0; pl < planned_workshifts.length; pl++) {
          dr.push({ periodo: pl, profs: tt });
          if (pl == planned_workshifts.length - 1) {
          }
        }

        return tt;
      }
    }
  }

  async salvar(post?: boolean) {
    // await this.saveOrPostTeam();
    var arrayToPost = {
      schedule: this.schedule,
      work_slots: [],
      health_team: [],
      published: false,
    };

    for (var w = 0; w < this.rowWeek.length; w++) {
      for (var sem = 0; sem < this.rowWeek[w].semana.length; sem++) {
        for (
          var dia = 0;
          dia < this.rowWeek[w].semana[sem].dias.length;
          dia++
        ) {
          if (this.rowWeek[w].semana[sem].dias[dia].dia != 0) {
            var diaBd;
            var dateBd = this.rowWeek[w].semana[sem].realStartDate;
            if (this.rowWeek[w].semana[sem].dias[dia].dia < 10) {
              diaBd =
                "0" + this.rowWeek[w].semana[sem].dias[dia].dia.toString();
            } else {
              diaBd = this.rowWeek[w].semana[sem].dias[dia].dia.toString();
            }

            var today = new Date(
              this.yearSchedule +
                "-" +
                this.realMonth +
                "-" +
                diaBd +
                "T" +
                dateBd +
                "Z"
            );
            var splitHora = dateBd.split(":");
            var starts = this.rowWeek[w].semana[sem].duration.split(":");
            var hh;
            if (splitHora[0] < 10) {
              hh = Number(splitHora[0].toString());
            } else {
              hh = splitHora[0];
            }
            today.setHours(hh, 0, 0);
            var jj;
            if (starts[0] < 10) {
              jj = Number(starts[0].toString());
            } else {
              jj = starts[0];
            }

            today.setHours(Number(today.getHours()) + Number(jj));
            var endDay = today.toLocaleTimeString();

            for (
              var prof = 0;
              prof < this.rowWeek[w].semana[sem].dias[dia].professionais.length;
              prof++
            ) {
              if (
                this.rowWeek[w].semana[sem].dias[dia].professionais[prof].id !=
                ""
              ) {
                arrayToPost.work_slots.push({
                  health_professional:
                    this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                      .id != `` ?  this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                      .id : null,
                  replaced: null,
                  period: this.rowWeek[w].semana[sem].periodo,
                  start_date:
                    this.yearSchedule + "-" + this.realMonth + "-" + diaBd, //+"T"+dateBd+"Z",
                  finalization_date:
                    this.yearSchedule + "-" + this.realMonth + "-" + diaBd, //+"T"+endDay+"Z",
                  planned_workshift: this.rowWeek[w].semana[sem].id,
                });
              }
            }
          }
        }
      }
    }
    this.teamProfessional.map((professional) => {
      arrayToPost.health_team.push({ health_professional: professional.id });
    });
    // this.teamProfessional = [];
    this.arrayToPost = arrayToPost;

    this.loading = true;
    await this.workSlotService.sendWorkSlot(arrayToPost).subscribe(
      (data) => {
        this.loading = false;
        this.openDialogSucessoSave("Escala salva com sucesso!");
        // const [ano, mes, dia] = this.month.split('-')
        // const dateFormat = ano + "-" + mes + "-" + '01';
        // this.healthProfessionalService
        //   .getSchedule(this.gradeAllData.id, this.selectedUnit.id, dateFormat, false)
        //   .subscribe(async (data) => {
        //     this.teamProfessional = [];

        //     this.month = data.month;
        //     this.gridId = data.id;
        //     this.loading = false;
        //     this.mountTurno(
        //       this.gradeAllData.planned_workshifts,
        //       data.month
        //     );

        //     this.createdDate = this.formatDate(data.created_at);
        //     this.updatedData = this.formatDate(data.updated_at);
        //     // await this.healthProfessionalService
        //     //   .getScheduleById(this.schedule)
        //     //   .subscribe((data) => {
        //     //     this.loading = false;
        //     //     this.scheduleHistory = data.history_work_slots;
        //     //   });
        //     this.loading = false;
        //   });
      },

      (error) => {
        this.loading = false;
        this.openDialog("Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }

  async publicar() {
    var arrayToPost = {
      schedule: this.schedule,
      work_slots: [],
      health_team: [],
      published: true,
    };

    for (var w = 0; w < this.rowWeek.length; w++) {
      for (var sem = 0; sem < this.rowWeek[w].semana.length; sem++) {
        for (
          var dia = 0;
          dia < this.rowWeek[w].semana[sem].dias.length;
          dia++
        ) {
          if (this.rowWeek[w].semana[sem].dias[dia].dia != 0) {
            var diaBd;
            var dateBd = this.rowWeek[w].semana[sem].realStartDate;
            if (this.rowWeek[w].semana[sem].dias[dia].dia < 10) {
              diaBd =
                "0" + this.rowWeek[w].semana[sem].dias[dia].dia.toString();
            } else {
              diaBd = this.rowWeek[w].semana[sem].dias[dia].dia.toString();
            }

            var today = new Date(
              this.yearSchedule +
                "-" +
                this.realMonth +
                "-" +
                diaBd +
                "T" +
                dateBd +
                "Z"
            );
            var splitHora = dateBd.split(":");
            var starts = this.rowWeek[w].semana[sem].duration.split(":");
            var hh;
            if (splitHora[0] < 10) {
              hh = Number(splitHora[0].toString());
            } else {
              hh = splitHora[0];
            }
            today.setHours(hh, 0, 0);
            var jj;
            if (starts[0] < 10) {
              jj = Number(starts[0].toString());
            } else {
              jj = starts[0];
            }

            today.setHours(Number(today.getHours()) + Number(jj));
            var endDay = today.toLocaleTimeString();

            for (
              var prof = 0;
              prof < this.rowWeek[w].semana[sem].dias[dia].professionais.length;
              prof++
            ) {
              // if (
              //   this.rowWeek[w].semana[sem].dias[dia].professionais[prof].id !=
              //   ""
              // ) {
                arrayToPost.work_slots.push({
                  health_professional:
                  this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                    .id != `` ?  this.rowWeek[w].semana[sem].dias[dia].professionais[prof]
                    .id : null,
                  replaced: null,
                  period: this.rowWeek[w].semana[sem].periodo,
                  start_date:
                    this.yearSchedule + "-" + this.realMonth + "-" + diaBd, //+"T"+dateBd+"Z",
                  finalization_date:
                    this.yearSchedule + "-" + this.realMonth + "-" + diaBd, //+"T"+endDay+"Z",
                  planned_workshift: this.rowWeek[w].semana[sem].id,
                });
              // }
            }
          }
        }
      }
    }
    this.teamProfessional.map((professional) => {
      arrayToPost.health_team.push({ health_professional: professional.id });
    });
    //this.teamProfessional = [];
    this.arrayToPost = arrayToPost;

    this.loading = true;
    await this.workSlotService.sendWorkSlot(arrayToPost).subscribe(
      (data) => {
        this.edited = false;
        this.loading = false;
        this.openDialogSucessoSave("Escala publicada com sucesso!");
        const [ano, mes, dia] = this.month.split("-");
        const dateFormat = ano + "-" + mes + "-" + "01";

        this.scheduleService
          .getSchedule(
            this.gradeAllData.id,
            this.selectedUnit.id,
            dateFormat,
            false
          )
          .subscribe(async (data) => {
            // this.teamProfessional = [];

            // this.gridId = data.id;
            this.loading = false;
            // this.mountTurno(
            //   this.gradeAllData.planned_workshifts,
            //   data.month)

            // this.createdDate = this.formatDate(filterData[0].created_at);
            // this.updatedData = this.formatDate(filterData[0].updated_at);
            // await this.healthProfessionalService
            //   .getScheduleById(this.schedule)
            //   .subscribe((data) => {
            //     this.scheduleHistory = data.history_work_slots;
            //   });
          });
      },

      (error) => {
        this.loading = false;
        this.openDialog("Ocorreu um erro, tente novamente!");
        return error;
      }
    );
  }
  calculateHour(position) {
    if (
      this.gradeAllData.planned_workshifts[position].start_time != "" &&
      this.gradeAllData.planned_workshifts[position].duration != ""
    ) {
      var today = new Date();
      var splitHora =
        this.gradeAllData.planned_workshifts[position].start_time.split(":");
      var starts =
        this.gradeAllData.planned_workshifts[position].duration.split(":");
      var hh;
      if (splitHora[0] < 10) {
        hh = Number(splitHora[0].toString());
      } else {
        hh = splitHora[0];
      }
      today.setHours(hh, 0, 0);

      var jj;
      if (starts[0] < 10) {
        jj = Number(starts[0].toString());
      } else {
        jj = starts[0];
      }

      today.setHours(Number(today.getHours()) + Number(jj));

      this.gradeAllData.planned_workshifts[position].horaFim =
        today.toLocaleTimeString();
    }
  }
  selectAllDays(item, periodo) {
    if (item.periodo_todo === false) {
      item.todos_par = false;
      item.todos_impar = false;
      item.periodo_todo = true;
      this.edited = true;

      //add day in calendar

      this.datesOfMonth.map((semana) => {
        semana.diasprof.map((diaProf) => {
          for (var mes = 0; mes < this.rowWeek.length; mes++) {
            for (
              var semana = 0;
              semana < this.rowWeek[mes].semana.length;
              semana++
            ) {
              for (
                var dias = 0;
                dias < this.rowWeek[mes].semana[semana].dias.length;
                dias++
              ) {
                for (
                  var profissional: number =
                    this.rowWeek[mes].semana[semana].dias[dias].professionais
                      .length - 1;
                  profissional >= 0;
                  profissional--
                ) {
                  if (
                    this.rowWeek[mes].semana[semana].dias[dias].dia ===
                      diaProf.dia &&
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id === "" &&
                    this.rowWeek[mes].semana[semana].periodo === periodo.periodo
                  ) {
                    let limitDate = new Date(
                      this.yearSchedule +
                        "-" +
                        this.realMonth +
                        "-" +
                        (Number(diaProf.dia) <= 9
                          ? "0" + Number(diaProf.dia)
                          : Number(diaProf.dia)) +
                        "T" +
                        periodo.realStartDate
                    );

                    limitDate = subMinutes(limitDate, -15);

                    const dataAtual = new Date();

                    if (!isAfter(limitDate, dataAtual)) {
                      //this.openDialog("Não é possível alterar essa data!");
                      continue;
                    }

                    const search = this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.find((element) => element.id === item.id);
                    if (!search) {
                      const classElement =
                        this.rowWeek[mes].semana[semana].dias[dias].dia +
                        "-" +
                        this.rowWeek[mes].semana[semana].periodo +
                        "-" +
                        item.id;

                      var checkValid = document.getElementById(classElement);
                      if (checkValid) {
                        this.rowWeek[mes].semana[semana].dias[
                          dias
                        ].professionais[profissional] = {
                          id: item.id,
                          name: item.name,
                          photo: item.photo,
                          crm: item.crm,
                          periodo: periodo.periodo,
                          profile: item.profile,
                        };

                        periodo.diasAtivos.push({
                          dia: diaProf.dia,
                          id_profissional: item.id,
                          name: item.name,
                          photo: item.photo,
                          crm: item.crm,
                          periodo: periodo.periodo,
                          profile: item.profile,
                        });

                        setTimeout(function () {
                          var elemento = document.getElementById(classElement);

                          if (elemento) {
                            elemento.classList.add("selected");
                          }
                        }, 10);
                      }
                      return;
                    }
                  }
                }
              }
            }
          }
        });
      });
      this.checkDaysInCalendar;
    } else {
      item.periodo_todo = false;

      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        for (
          var semana = 0;
          semana < this.rowWeek[mes].semana.length;
          semana++
        ) {
          for (
            var dias = 0;
            dias < this.rowWeek[mes].semana[semana].dias.length;
            dias++
          ) {
            for (
              var profissional = 0;
              profissional <
              this.rowWeek[mes].semana[semana].dias[dias].professionais.length;
              profissional++
            ) {
              if (
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id === item.id
              ) {
                let limitDate = new Date(
                  this.yearSchedule +
                    "-" +
                    this.realMonth +
                    "-" +
                    (Number(this.rowWeek[mes].semana[semana].dias[dias].dia) <=
                    9
                      ? "0" +
                        Number(this.rowWeek[mes].semana[semana].dias[dias].dia)
                      : Number(
                          this.rowWeek[mes].semana[semana].dias[dias].dia
                        )) +
                    "T" +
                    periodo.realStartDate
                );

                limitDate = subMinutes(limitDate, -15);

                const dataAtual = new Date();

                if (!isAfter(limitDate, dataAtual)) {
                  //this.openDialog("Não é possível alterar essa data!");
                  continue;
                }
                const classElement =
                  this.rowWeek[mes].semana[semana].dias[dias].dia +
                  "-" +
                  this.rowWeek[mes].semana[semana].periodo +
                  "-" +
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id;

                setTimeout(function () {
                  var elemento = document.getElementById(classElement);

                  if (elemento) {
                    elemento.classList.remove("selected");
                  }
                }, 10);

                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ] = {
                  id: "",
                };
              }
            }
          }
        }
      }
    }
  }
  selectParImparDays(item, periodo, par) {
    if (par ? item.todos_par === false : item.todos_impar === false) {
      par ? (item.todos_par = true) : (item.todos_impar = true);
      this.edited = true;
      item.periodo_todo = false;

      //add day in calendar

      this.datesOfMonth.map((semana) => {
        semana.diasprof.map((diaProf) => {
          for (var mes = 0; mes < this.rowWeek.length; mes++) {
            for (
              var semana = 0;
              semana < this.rowWeek[mes].semana.length;
              semana++
            ) {
              for (
                var dias = 0;
                dias < this.rowWeek[mes].semana[semana].dias.length;
                dias++
              ) {
                for (
                  var profissional: number =
                    this.rowWeek[mes].semana[semana].dias[dias].professionais
                      .length - 1;
                  profissional >= 0;
                  profissional--
                ) {
                  if (
                    this.rowWeek[mes].semana[semana].dias[dias].dia ===
                      diaProf.dia &&
                    this.rowWeek[mes].semana[semana].dias[dias].professionais[
                      profissional
                    ].id === "" &&
                    this.rowWeek[mes].semana[semana].periodo ===
                      periodo.periodo &&
                    (par
                      ? Number(diaProf.dia) % 2 === 0
                      : Number(diaProf.dia) % 2 === 1)
                  ) {
                    let limitDate = new Date(
                      this.yearSchedule +
                        "-" +
                        this.realMonth +
                        "-" +
                        (Number(diaProf.dia) <= 9
                          ? "0" + Number(diaProf.dia)
                          : Number(diaProf.dia)) +
                        "T" +
                        periodo.realStartDate
                    );

                    limitDate = subMinutes(limitDate, -15);

                    const dataAtual = new Date();

                    if (!isAfter(limitDate, dataAtual)) {
                      //this.openDialog("Não é possível alterar essa data!");
                      continue;
                    }

                    const search = this.rowWeek[mes].semana[semana].dias[
                      dias
                    ].professionais.find((element) => element.id === item.id);
                    if (!search) {
                      const classElement =
                        this.rowWeek[mes].semana[semana].dias[dias].dia +
                        "-" +
                        this.rowWeek[mes].semana[semana].periodo +
                        "-" +
                        item.id;

                      var checkValid = document.getElementById(classElement);
                      if (checkValid) {
                        this.rowWeek[mes].semana[semana].dias[
                          dias
                        ].professionais[profissional] = {
                          id: item.id,
                          name: item.name,
                          photo: item.photo,
                          crm: item.crm,
                          periodo: periodo.periodo,
                          profile: item.profile,
                        };

                        periodo.diasAtivos.push({
                          dia: diaProf.dia,
                          id_profissional: item.id,
                          name: item.name,
                          photo: item.photo,
                          crm: item.crm,
                          periodo: periodo.periodo,
                          profile: item.profile,
                        });

                        setTimeout(function () {
                          var elemento = document.getElementById(classElement);

                          if (elemento) {
                            elemento.classList.add("selected");
                          }
                        }, 10);
                      }
                      return;
                    }
                  }
                }
              }
            }
          }
        });
      });
      this.checkDaysInCalendar;
    } else {
      par ? (item.todos_par = false) : (item.todos_impar = false);

      for (var mes = 0; mes < this.rowWeek.length; mes++) {
        for (
          var semana = 0;
          semana < this.rowWeek[mes].semana.length;
          semana++
        ) {
          for (
            var dias = 0;
            dias < this.rowWeek[mes].semana[semana].dias.length;
            dias++
          ) {
            for (
              var profissional = 0;
              profissional <
              this.rowWeek[mes].semana[semana].dias[dias].professionais.length;
              profissional++
            ) {
              if (
                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ].id === item.id &&
                (par
                  ? Number(this.rowWeek[mes].semana[semana].dias[dias].dia) %
                      2 ===
                    0
                  : Number(this.rowWeek[mes].semana[semana].dias[dias].dia) %
                      2 ===
                    1)
              ) {
                let limitDate = new Date(
                  this.yearSchedule +
                    "-" +
                    this.realMonth +
                    "-" +
                    (Number(this.rowWeek[mes].semana[semana].dias[dias].dia) <=
                    9
                      ? "0" +
                        Number(this.rowWeek[mes].semana[semana].dias[dias].dia)
                      : Number(
                          this.rowWeek[mes].semana[semana].dias[dias].dia
                        )) +
                    "T" +
                    periodo.realStartDate
                );

                limitDate = subMinutes(limitDate, -15);

                const dataAtual = new Date();

                if (!isAfter(limitDate, dataAtual)) {
                  //this.openDialog("Não é possível alterar essa data!");
                  continue;
                }

                const classElement =
                  this.rowWeek[mes].semana[semana].dias[dias].dia +
                  "-" +
                  this.rowWeek[mes].semana[semana].periodo +
                  "-" +
                  this.rowWeek[mes].semana[semana].dias[dias].professionais[
                    profissional
                  ].id;

                setTimeout(function () {
                  var elemento = document.getElementById(classElement);

                  if (elemento) {
                    elemento.classList.remove("selected");
                  }
                }, 10);

                this.rowWeek[mes].semana[semana].dias[dias].professionais[
                  profissional
                ] = {
                  id: "",
                };
              }
            }
          }
        }
      }
    }
  }
  selectHistory(dia: any) {
    if (dia.dia <= 0) {
      return;
    } else {
      let dataHistory: History[] = [];
      this.scheduleHistory.map((professional) => {
        const day = parseInt(
          professional.start_date[professional.start_date.length - 2] +
            professional.start_date[professional.start_date.length - 1]
        );

        if (dia.dia === day) {
          dataHistory.push(professional);
        }
      });

      dataHistory.map(async (history) => {
        if (!history.created_ate && !history.updated_ate) {
          history.updated_ate = this.formatDate(history.updated_at);
        }
        history.update_by = history.updated_by_name;

        const prof = this.healthUnitsProfissionais.find(
          (profissional) =>
            profissional.health_professional_id === history.health_professional
        );
        if (prof) {
          history.health_professional = prof.name;
        }
      });
      dataHistory = dataHistory.reverse();
      if (dataHistory.length > 0) {
        const dialogRef = this.dialog.open(ScheduleHistoryComponent, {
          width: "1090px",
          height: "500px",
          panelClass: "azulPanel",
          //disableClose: true,
          data: {
            dia: dia,
            history: dataHistory,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          dataHistory = [];
        });
      }
    }
  }

  async getProfissionaisCategory(occupation_area_id, areas_of_expertise) {
    await this.healthProfessionalService
      .getCompanyHealthProfessionalsCategory(
        occupation_area_id,
        areas_of_expertise
      )
      .subscribe(async (data) => {
        this.healthUnitsProfissionais = data["results"];

        await this.healthUnitsProfissionais.sort(function (a, b) {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
        await this.healthUnitsProfissionais.unshift({
          name: "Profissionais",
          id: 0,
        });

        await this.mountSchedule(this.gradeAllData.id);
      });
  }

  somartempos(tempo1, tempo2) {
    if (tempo1 && tempo2) {
      var array1 = tempo1.split(":");

      var tempo_seg1: any =
        parseInt(array1[0]) * 3600 +
        parseInt(array1[1]) * 60 +
        parseInt(array1[2]);

      var array2 = tempo2.split(":");

      var tempo_seg2: any =
        parseInt(array2[0]) * 3600 +
        parseInt(array2[1]) * 60 +
        parseInt(array2[2]);

      var tempofinal = parseInt(tempo_seg1) + parseInt(tempo_seg2);

      var hours = Math.floor(tempofinal / (60 * 60));

      if (hours > 24) hours = hours - 24;

      var divisorMinutos = tempofinal % (60 * 60);

      var minutes = Math.floor(divisorMinutos / 60);
      if (minutes > 60) {
        minutes = minutes - 60;
        hours = hours + 1;
      }

      var divisorSeconds = divisorMinutos % 60;

      var seconds = Math.ceil(divisorSeconds);

      var contador = "";

      if (hours < 10) {
        contador = "0" + hours + ":";
      } else {
        contador = hours + ":";
      }

      if (minutes < 10) {
        contador += "0" + minutes + ":";
      } else {
        contador += minutes + ":";
      }

      if (seconds < 10) {
        contador += "0" + seconds;
      } else {
        contador += seconds;
      }

      return contador;
    }
  }
  read(item) {}

  toggleOverlay(): void {
    this.isOverlayVisible = !this.isOverlayVisible;
  }
  exportPdf() {
    this.loading = true;
    this.scheduleService.exportScheduleTable(this.gradeSchedule.id).subscribe(
      (result) => {
        window.open((result as any).file_url);
        this.loading = false;
      },
      (err) => {
        this.openDialog("Não foi possível exportar o pdf");
        this.loading = false;
        return;
      }
    )
  }

  workSlotDonationsSend(){
    this.loading = true;
    this.scheduleService.workSlotDonationsSend(this.gradeSchedule.id).subscribe(
      (result) => {
      
        this.loading = false;

        this.openDialogSucessoSave("Convites enviados com sucesso!");
      },
      (err) => {
        this.openDialog("Não foi possível convidar");
        this.loading = false;
        return;
      }
    )
  }
}
