import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { ScheduleService } from "src/app/_services/schedule.service";
export interface DialogData {
  description: string;
  turn: any;
  enableCancel: boolean;
  escala: any;
}
@Component({
  selector: "app-dialog-edit",
  templateUrl: "./dialog-edit.component.html",
  styleUrls: ["./dialog-edit.component.scss"],
})
export class DialogEditComponent implements OnInit {
  form = new FormGroup({
    name: new FormControl(""),
    jornada: new FormControl(""),
    valorHora: new FormControl(""),
    valorFaturar: new FormControl(""),
  });
  loading: boolean;
  title: string;
  constructor(
    public dialogRef: MatDialogRef<DialogEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private healthProfessionalService: HealthProfessionalsService,
    private scheduleService: ScheduleService
  ) {}

  ngOnInit() {
    this.title = "Edição da Escala";
    this.form.controls.valorHora.setValue(this.data.escala.hourly_value);
    this.form.controls.valorFaturar.setValue(this.data.escala.invoice_value);
  }
  onNoClick(): void {
    this.loading = true;
    const body = {
      published: true,
      invoice_value: this.form.controls.valorFaturar.value,
      hourly_value: this.form.controls.valorHora.value,
    };
    this.scheduleService
      .editSchedule(this.data.escala.id, body)
      .subscribe((data) => {
        this.loading = false;
        this.dialogRef.close(true);
      });

    this.loading = false;
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
