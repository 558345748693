import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ViaCepResponse } from "../_models/viacep_response.model";
import { State } from "../_models/state.model";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  viaCepApi = "https://viacep.com.br/ws/";
  ibgeApi = "https://servicodados.ibge.gov.br/api/v1/";
  constructor(private http: HttpClient) {}

  public getCepLocation(cep: String): Observable<ViaCepResponse> {
    return this.http.get<ViaCepResponse>(this.viaCepApi + cep + "/json/");
  }

  public getUfLocation(): Observable<State[]> {
    return this.http.get<State[]>(this.ibgeApi + "localidades/estados");
  }
}
