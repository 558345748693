import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlannedWorkshiftService {
  constructor(private http: HttpClient) {}

  public sendPlannedWork(body) {
    return this.http.post<any>(environment.apiUrl + "planned-workshift/", body);
  }
}
