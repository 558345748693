import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReviewService {
  constructor(private http: HttpClient) {}

  public removeReviewProfessional(body) {
    return this.http.post<any>(environment.apiUrl + "unlink/", body);
  }

  public getAllReview(body) {
    return this.http.post<any[]>(environment.apiUrl + "review/", body);
  }
  public getReviewReport(body) {
    return this.http.post<any[]>(
      environment.apiUrl + "reports/health-unit/review/",
      body
    );
  }
}
