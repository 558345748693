import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GridService {
  constructor(private http: HttpClient) {}

  public getGrids(unidade): Observable<any[]> {
    return this.http.get<any[]>(
      environment.apiUrl + "grid/?health_unit_id=" + unidade
    );
  }

  public getGrid(id): Observable<any> {
    return this.http.get<any[]>(environment.apiUrl + "grid/" + id + "/");
  }

  public deleteGrid(id) {
    return this.http.delete<any>(environment.apiUrl + "grid/" + id);
  }
  public sendGrid(body) {
    return this.http.post<any>(environment.apiUrl + "grid/", body);
  }
  public updateGrid(id: string, body: any) {
    return this.http.patch<any>(environment.apiUrl + "grid/" + id + "/", body);
  }
}
