import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  constructor(private http: HttpClient) {}

  public getGridsOfSchedule(grid, unit, month): Observable<any[]> {
    return this.http.get<any>(
      environment.apiUrl +
        "schedule/?grid_id=" +
        grid +
        "&health_unit_id=" +
        unit +
        "&initial_date=" +
        month
    );
  }

  public getSchedule(grid, unit, month, review_status): Observable<any> {
    return this.http.get<any>(
      environment.apiUrl +
        "get-schedule/?grid_id=" +
        grid +
        "&health_unit_id=" +
        unit +
        "&date=" +
        month +
        "&review_status=" +
        review_status
    );
  }

  public getScheduleById(id): Observable<any> {
    return this.http.get<any>(environment.apiUrl + "schedule/" + id + "/?");
  }

  public editSchedule(id, data): Observable<any> {
    return this.http.patch<any>(
      environment.apiUrl + "schedule/" + id + "/?",
      data
    );
  }
  public getScheduleByUnitId(unitId): Observable<any> {
    return this.http.get<any>(
      environment.apiUrl + "schedule/?health_unit_id=" + unitId
    );
  }

  public saveSchedule(id: string, data: any) {
    return this.http.patch<any>(
      environment.apiUrl + "schedule-history/" + id + "/",
      data
    );
  }

  public createSchedule(body) {
    return this.http.post<any>(environment.apiUrl + "schedule/", body);
  }

  public deleteEscala(id) {
    return this.http.delete<any>(environment.apiUrl + "schedule/" + id + "/");
  }
  public exportScheduleTable(id) {
    return this.http.get<any[]>(
      environment.apiUrl + "work-slot/report?schedule_id="+id ,
      {}
    );
  }

  workSlotDonationsSend(id){
    return this.http.post<any>(environment.apiUrlV2 + `schedule/${id}/donate-empty-work-slots/`, null);
  }

}
