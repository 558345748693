import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  public getReviewSchedule(body: any) {
    return this.http.post<any>(
      environment.apiUrl + "reports/health-unit/scale/",
      body
    );
  }

  public exportReviewScheduleExcel(body: any) {
    return this.http.post<any>(
      environment.apiUrl + "reports/health-unit/scale/csv/",
      body
    );
  }

  public exportProfessionalsPdf() {
    const body: any = { report_type: "pdf" };
    return this.http.post<any>(
      environment.apiUrl + "reports/health-professionals/",
      body
    );
  }
  public exportProfessionalsXlxs() {
    const body: any = { report_type: "xls" };
    return this.http.post<any>(
      environment.apiUrl + "reports/health-professionals/",
      body
    );
  }
  public reportHours(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/by-health-professional/",
      body
    );
  }
  public reportProfessional(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/health-professional/",
      body
    );
  }

  public reportCSV(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/by-health-professional/csv/",
      body
    );
  }

  public reportUnityCSV(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/health-unit/csv/",
      body
    );
  }

  public reportUnityPDF(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/by-health-professional/pdf/detailed/",
      body
    );
  }

  public reportUnitsDash(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/health-unit/",
      body
    );
  }

  public reportUnits(body) {
    return this.http.post<any>(
      environment.apiUrl + "reports/health-unit/",
      body
    );
  }
}
