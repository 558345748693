import { Component, OnInit } from "@angular/core";
import { HealthUnitService } from "src/app/_services/health-unit.service";
import { HealthProfessionalsService } from "src/app/_services/health-professionals.service";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "../dialog/dialog.component";
import { ModalService } from "src/app/_services/modal.service";
import { DetailRegistroComponent } from "../detailregistro/detailregistro.component";
import { CreateGridComponent } from "../create-grid/create-grid.component";
import { CreateScheduleComponent } from "../create-schedule/create-schedule.component";
import { Router } from "@angular/router";
import { DialogEditComponent } from "../dialog-edit/dialog-edit.component";
import { setDate, subHours, set, getDate, lastDayOfMonth } from "date-fns";
import { ScheduleService } from "src/app/_services/schedule.service";
import { GridService } from "src/app/_services/grid.service";
import { NotificationService } from "src/app/_services/notification.service";
import { HttpParams } from "@angular/common/http";
import { UnitNotification } from "src/app/_models/unit-notification";
import {
  OwlDateTimeIntl,
  DateTimeAdapter,
  OwlDateTimeComponent,
} from "@danielmoncada/angular-datetime-picker";
@Component({
  selector: "medilog-notification-unit",
  templateUrl: "./notification-unit.component.html",
  styleUrls: ["./notification-unit.component.scss"],
})
export class NotificationUnitComponent implements OnInit {
  createdDate: any;
  updatedData: any;
  monthData: any;

  constructor(
    private router: Router,
    private healthUnitService: HealthUnitService,
    public dialog: MatDialog,
    private healthProfessionalService: HealthProfessionalsService,
    private scheduleService: ScheduleService,
    private gridService: GridService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    public dateTimeAdapter: DateTimeAdapter<any>,
    public owlDateTimeIntl: OwlDateTimeIntl
  ) {
    document.getElementById("menu").click();
    this.dateTimeAdapter.setLocale("pt-BR");

    owlDateTimeIntl.setBtnLabel = "";
    owlDateTimeIntl.cancelBtnLabel = "";
  }

  private apiUrl = "sua-url-api-aqui";

  text: string;
  initial: any;
  healthUnits;
  selectedUnit;
  loading = true;
  gradeData;
  visualizarData = [];
  selectedVisualizar;
  gradeAllData;
  title;
  dialogs: DetailRegistroComponent;
  allGrid;
  gradeSchedule: any = [];
  pt: any;
  result: UnitNotification[] = [];

  modelDataInicio: any = set(new Date(), {
    date: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  modelDataFim: any = set(new Date(), {
    date: getDate(lastDayOfMonth(new Date())),
    hours: 23,
    minutes: 59,
    seconds: 59,
  });
  enableCreate = true;
  async ngOnInit() {
    this.pt = {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda Feira",
        "Terça Feira",
        "Quarta Feira",
        "Quinta Feira",
        "Sexta Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "mm/dd/yy",
    };
    this.title = localStorage.getItem("titulo");

    const params = {
      health_unit_status: "enable",
    };

    this.healthUnitService.getHealthUnits(params).subscribe((data) => {
      this.loading = false;
      this.healthUnits = data;

      this.healthUnits.results.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      this.healthUnits.results.unshift({ name: "Selecione a Unidade", id: 0 });

      //this.healthUnits.results.reverse();
    });

    this.selectedUnit = { name: "Selecione a Unidade", id: 0 };
  }

  async requestData() {
    if (this.modelDataInicio == "") {
      this.openDialogMsg("Selecione um mês!");
    }
    this.loading = true;

    const dateFormat = this.modelDataInicio.toISOString().split("T");
    const dateFormatEnd = this.modelDataFim.toISOString().split("T");
    await this.notificationService
      .getNotificationsUnit(
        this.selectedUnit.id != "0" && this.selectedUnit.id != null
          ? this.selectedUnit.id
          : null,
        dateFormat[0],
        dateFormatEnd[0]
      )
      .subscribe(
        (data) => {
          if (!data.length) {
            this.openDialog("O relatório não foi encontrado para a Unidade!");
            this.loading = false;
            return;
          }

          this.loading = false;

          if (data) {
            this.result = data;
          }
        },
        (err) => {
          this.enableCreate = true;
          this.openDialog("O relatório não foi encontrado para a Unidade");
          this.loading = false;
          return;
        }
      );
  }

  formatHour(data) {
    var dataHora = data.split(":");
    var segundos = dataHora[2].split(".");
    return dataHora[0] + ":" + dataHora[1] + ":" + segundos[0];
  }

  openDialog(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "vermelhoPanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatDate(data: string) {
    var array = data.split("");
    var year = array[0] + array[1] + array[2] + array[3];
    var month = array[5] + array[6];
    var day = array[8] + array[9];
    var hour = array[11] + array[12];
    var hourInt = parseInt(hour) - 3;

    if (hourInt < 0) {
      hourInt = hourInt + 24;
    }
    var hour = hourInt.toLocaleString();
    var minute = array[14] + array[15];
    return day + "/" + month + "/" + year + "  " + hour + ":" + minute;
  }

  formatDatePT(data: Date) {
    return data.toLocaleDateString();
  }
  openDialogMsg(texto): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "570px",
      height: "170px",
      panelClass: "verdePanel",
      data: { description: texto },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  chosenDateHandler(event: any, input: string) {
    if (input === "ATE") {
      this.modelDataFim = event?.selected;
    } else {
      this.modelDataInicio = event?.selected;
    }
  }
}
