// import { Observable } from "rxjs";
// import { IHealthNotification } from "../_models/notificationsSchedule";
// import { HttpClient } from "@angular/common/http";
// import { Injectable } from "@angular/core";
// import { environment } from "src/environments/environment";

// @Injectable({
//   providedIn: "root",
// })
// export class HealthNotificationsService {
//   constructor(private http: HttpClient) {}

//   public getNotifications(unidade): Observable<any[]> {
//     return this.http.get<any[]>(
//       environment.apiUrl + "#operation" + unidade
//     );
//   }

//   public getNotification(id): Observable<any> {
//     return this.http.get<any[]>(environment.apiUrl + "/notifications_schedules_list/" + id + "/");
//   }
// }
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { cleanProperties } from "src/utils";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  public getNotificationsSchedules(
    gridId: String,
    unitId: String,
    dateStart: String,
    dateEnd: String
  ): Observable<any> {
    const params = {
      health_unit: unitId,
      grid: gridId,
      report_start_date: dateStart,
      report_end_date: dateEnd,
    };
    return this.http.get(`${environment.apiUrl}notifications/schedules/`, {
      params: cleanProperties(params),
    });
  }

  public getNotificationsUnit(
    unitId: String,
    dateStart: String,
    dateEnd: String
  ): Observable<any> {
    const params = {
      health_unit: unitId,
      report_start_date: dateStart,
      report_end_date: dateEnd,
    };
    return this.http.get(`${environment.apiUrl}notifications/health-units/`, {
      params: cleanProperties(params),
    });
  }
}
